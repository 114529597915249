import React, { useRef, useEffect, forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import classNames from "classnames";

// components
import Pagination from "./Pagination";
import Scrollbar from "./Scrollbar";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

interface GlobalFilterProps {
  totalItems: number;
  globalFilter: any;
  setGlobalFilter: any;
  searchBoxClass: any;
  handleSearch?: (value: string) => void;
}

// Define a default UI for filtering
const GlobalFilter = ({
  totalItems,
  globalFilter,
  searchBoxClass,
  handleSearch,
}: GlobalFilterProps) => {
  const count = totalItems;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    if (handleSearch) {
      handleSearch(value || '');
    }
  }, 200);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Search :{" "}
        <input
          type="search"
          value={value || ""}
          onChange={(e: any) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
};

interface IndeterminateCheckboxProps {
  indeterminate: boolean;
  children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <div className="form-check pe-0 ">
      <input
        type="checkbox"
        className="form-check-input "
        ref={resolvedRef}
        {...rest}
      />
    </div>
  );
});

interface CustomPagination {
  pagination?: boolean;
  pageCount?: number;
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  query?: string;
  handleLimitChange?: (pageSize: number) => void;
  handlePageChange?: (pageNumber: number) => void;
  handleSearch?: (search: string) => void;
}

interface TableProps extends CustomPagination {
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  isFetching?: boolean;
  sizePerPageList?: {
    text: string;
    value: number;
  }[];
  columns: {
    Header: string;
    accessor: any;
    sort?: boolean;
    Cell?: any;
    className?: string;
  }[];
  data: any[];
  pageSize?: number;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  onRowClick?: (row: any, cell: any) => void;
}

const Table = (props: TableProps) => {
  const isSearchable = props.isSearchable || false;
  const isSortable = props.isSortable || false;
  const pagination = props.pagination || false;
  const isSelectable = props.isSelectable || false;
  const isExpandable = props.isExpandable || false;
  const sizePerPageList = props.sizePerPageList || [];
  const isFetching = props.isFetching || false;
  const pageCount = props.pageCount || 0;
  const currentPage = props.pageNumber || 0;
  const pageSize = props.pageSize || 0;
  const totalItems = props.totalItems || 0;
  const query = props.query || '';
  const { t } = useTranslation();
  const handleLimitChange = props.handleLimitChange;
  const handlePageChange = props.handlePageChange;
  const handleSearch = props.handleSearch;

  let plugins = [];
  if (isSearchable) plugins.push(useGlobalFilter);
  if (isSortable) plugins.push(useSortBy);
  if (isExpandable) plugins.push(useExpanded);
  if (pagination) plugins.push(usePagination);
  if (isSelectable) plugins.push(useRowSelect);

  const compareIgnoreCase = (a: string, b: string) => {
    let r1 = a.toLowerCase();
    let r2 = b.toLowerCase();
    if (r1 < r2) {
      return -1;
    }
    if (r1 > r2) {
      return 1;
    }
    return 0;
  };

  const dataTable = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: { pageSize: props.pageSize || 10 },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(
            row1.values[columnName],
            row2.values[columnName]
          );
        },
      },
    },
    ...plugins,
    (hooks) => {
      isSelectable &&
        hooks.visibleColumns.push((columns: any) => [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  indeterminate={
                    dataTable.state.selectedRowIds &&
                    Object.keys(dataTable.state.selectedRowIds).length > 0 &&
                    Object.keys(dataTable.state.selectedRowIds).length < props.data.length
                  }
                />
              </div>
            ),
            Cell: ({ row }: any) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            ),
          },
          ...columns,
        ]);

      isExpandable &&
        hooks.visibleColumns.push((columns: any) => [
          {
            id: "expander",
            Header: ({
              getToggleAllRowsExpandedProps,
              isAllRowsExpanded,
            }: any) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? "-" : "+"}
              </span>
            ),
            Cell: ({ row }) =>
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  {row.isExpanded ? "-" : "+"}
                </span>
              ) : null,
          },
          ...columns,
        ]);
    }
  );

  let rows = dataTable.rows;

  return (
    <>
      <Scrollbar>
        {isSearchable && (
          <GlobalFilter
            totalItems={totalItems}
            globalFilter={query}
            setGlobalFilter={dataTable.setGlobalFilter}
            searchBoxClass={props.searchBoxClass}
            handleSearch={handleSearch}
          />
        )}

        <div className="table-responsive">
          <table
            {...dataTable.getTableProps()}
            className={classNames(
              "table table-centered react-table",
              props.tableClass
            )}
          >
            <thead className={props.theadClass}>
              {(dataTable.headerGroups || []).map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {(headerGroup.headers || []).map((column: any) => (
                    <th
                      {...column.getHeaderProps(
                        column.sort && column.getSortByToggleProps()
                      )}
                      className={classNames({
                        sorting_desc: column.isSortedDesc === true,
                        sorting_asc: column.isSortedDesc === false,
                        sortable: column.sort === true,
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...dataTable.getTableBodyProps()}>
              {!isFetching && !rows.length && (
                <tr className="text-center text-danger fs-18">
                  <td
                    colSpan={
                      props.columns.length < 10 ? props.columns.length : 8
                    }
                  >
                    {t("No_Data_Found")}
                  </td>
                </tr>
              )}
              {isFetching ? (
                <tr className="text-center text-success fs-18 p-10">
                  <td
                    style={{ padding: "80px" }}
                    colSpan={
                      props.columns.length < 10 ? props.columns.length : 8
                    }
                  >
                    <Loader />
                  </td>
                </tr>
              ) : (
                (rows || []).map((row: any, i: number) => {
                  dataTable.prepareRow(row);
                  return (
                    <tr
                      className={classNames({
                        "cursor-pointer": props?.onRowClick,
                      })}
                      {...row.getRowProps()}
                    >
                      {(row.cells || []).map((cell: any,index:number) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                              },
                            ])}
                            onClick={() => {
                              if (props.onRowClick && index!==0) {
                                props.onRowClick(row, cell);
                              }
                            }}
                            className="text-wrap"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </Scrollbar>
      {pagination && (
        <Pagination
          tableProps={{
            pageCount: pageCount,
            pageOptions: Array.from({ length: pageCount }, (_, i) => i + 1),
            setPageSize: (page: number) => {
              if (handleLimitChange) {
                handleLimitChange(page);
              }
            },
            gotoPage: (page: number) => {
              if (handlePageChange) {
                handlePageChange(page);
              }
            },
            state: {
              pageIndex: currentPage - 1,
              pageSize: pageSize,
            },
          }}
          sizePerPageList={sizePerPageList}
        />
      )}
    </>
  );
};

export default Table;
