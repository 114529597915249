import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAllTrips } from "../../../helpers";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const ActiveDriver = () => {
  const [activedriver, setActivedriver] = useState([]);
  const {t} =useTranslation()
  const [ isfetchLatestTrip, setIsfetchLatestTrip]=useState<boolean>(true)
  const GetActiveTrips = async () => {
    try {
      const response = await getAllTrips({
        pageNumber: 1,
        pageSize: 10,
        status: "ACTIVE",
      });

      if (response.statusCode === 200) {
        setActivedriver(response?.data?.items);
        setIsfetchLatestTrip(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetActiveTrips();
  }, []);
  return (
    <Card className="overflow-auto py-3 h-100">
      <Card.Body className="pt-0 overflow py-3">
        <div className="activeusers pt-2">
          <h3 className="fw-bold text-center">{t("Active_Drivers")}</h3>
          <hr />
        </div>

        <div>
      { isfetchLatestTrip?<Loader/>: activedriver.length > 0 ? (
        activedriver.map((data:any, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <div className="custom-image rounded-circle bg-secondary">
                 
                </div>

                <div className="ms-2">
                  <h4 className="fw-bold">{data?.driverName }</h4>
                  <p className="text-secondary">{data?.driverAvgRating}</p>
                </div>
              </div>
            </div>
            <hr className="m-2" />
          </div>
        ))
      ) : (
        <p>No active trips available.</p>
      )}
    </div>
      </Card.Body>
    </Card>
  );
};

export default ActiveDriver;
