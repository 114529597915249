import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getdashboard } from "../../../helpers/api/dashboard";
import Loader from "../../../components/Loader";

const SalesOverviewChart = ({
  filter,
  monthId,
}: {
  filter: "YEAR" | "MONTH" | "WEEK" | "TODAY";
  monthId?: number;
}) => {
  const [graphData, setGraphData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const currentMonth = today.getMonth() + 1;

  const fetchdata = async () => {
    try {
      const params: any = { type: "SALES_GRAPH", filters: filter };

      if (filter === "MONTH") {
        params.month = currentMonth;
      }
      if (filter === "MONTH" && monthId) {
        params.month = monthId;
      }
      const response = await getdashboard(params);

      if (response?.statusCode === 200) {
        setGraphData(response.data);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  useEffect(() => {
    fetchdata();
  }, [filter, monthId]);


  
  const xAxisCategories = () => {
    if (filter === "YEAR") {
      return [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    } else if (filter == "MONTH") {
      return Array.from({ length: 31 }, (_, i) => (i + 1).toString());
    }
  };

  const series: ApexAxisChartSeries = [
    {
      name: "Revenue",

      data: graphData?.map((item: any) => item.sales.revenue),
    },
    {
      name: "Transaction fees",
      data: graphData?.map((item: any) => item.sales.transactionFees),
    },
  ];

  const options: any = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [3, 3],
      curve: "smooth",
    },
    plotOptions: {
      line: {
        show: true,
      },
    },

    xaxis: {
      categories: xAxisCategories(),
    },
    yaxis: {
      title: {
        text: "Sales",
        offsetX: 20, 
      },
   
    },
    annotations: {
      yaxis: [
        {
          y: 450,
          borderColor: "#00B39B",
          label: {
            borderColor: "#00B39B",
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              color: "#00B39B",
            },
            text: "(+5) more in 2024",
          },
        },
      ],
    },
    grid: {
      show: true,
      borderColor: "#E4E4E4",
      strokeDashArray: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0.4,
        stops: [0, 100],
      },
    },
    colors: ["#00B39B", "#60D3C6"],
  };
  return (
    <div>

      {loading?  <Loader />: (<ReactApexChart
        options={options}
        series={series}
        type="line"
        height={400}
      
      />

      )}
      
    </div>
  );
};

export default SalesOverviewChart;
