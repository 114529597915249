import React, { useState } from "react";
import {
    FormControl,
    InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

let debounce: any = undefined;
const Search = ({ pagination,width }: { pagination: any;width?:any ;}) => {
    const [value, setValue] = useState<any>("");
  const { t, i18n } = useTranslation();

    const onChange = (value: any) => {
        if(debounce){
            clearInterval(debounce);
        }
        debounce = setTimeout(() => {
            if (pagination?.handleSearch) {
                pagination?.handleSearch(value || "");
            }
        }, 400);
    }
    return (
        <InputGroup className={`align-items-center bgColor rounded-2 ${width} `}  >
       <FaSearch size={18} className="ms-2 theme-color"/>
            <FormControl
                className="search-input bgColor border-0 "
                type="search"
                value={value}
              
                onChange={(e:any)=>{
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={t("Search_here")}
            />
        </InputGroup>
    );
};

export default Search;
