import { useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { InputCtrl, SelectCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteUser, getAllRoles, getUser, saveUser } from "../../helpers";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useToast } from "../../hooks";
import Loader from "../../components/Loader";
import PhoneCtrl from "../../components/PhoneCtrl";
import { useTranslation } from "react-i18next";
const defaultValues: {
  Id: number;
  firstName: string;
  lastName: string;
  Email: string;
  flair: string;
  roleId: number | string;
  statusId: number | string;
  password: string;
  ProfilePicId: number;
  FullFileUrl: string;
  phoneNumber: number | string;
} = {
  Id: 0,
  firstName: "",
  lastName: "",
  Email: "",
  flair: "",
  password: "",
  FullFileUrl: "",
  ProfilePicId: 0,
  statusId: 1,
  roleId: 2,
  phoneNumber: "",
};

const ConfigDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [roles, setRoles] = useState<any[]>([]);
  const { showToast, dissmisToast } = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();

  const [apiStatus] = useState({
    inprogress: false,
  });
  const [phoneValue, setPhoneFields] = useState({
    ContactPhone: "",
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    Id: number;
    firstName: string;
    Email: string;
    flair: string;
    roleId: number | string;
    statusId: number | string;
    password: string;
    lastName: string;
    ProfilePicId: number;
    FullFileUrl: string;
    phoneNumber: number | string;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    Id: number;
    firstName: string;
    Email: string;
    lastName: string;
    password: string;
    roleId: number | string;
    statusId: number | string;
    phoneNumber: number | string;
    ProfilePicId: number;
    FullFileUrl: string;
  }> = async (data) => {
    try {
      const response = await saveUser({
        Id: data.Id,
        firstName: data.firstName,
        lastName: data.lastName,
        Email: data.Email,
        password: data.password,
        roleId: data.roleId,
        statusId: data.statusId,
        phoneNumber: "+" + data.phoneNumber,
        FullFileUrl: data.FullFileUrl,
        ProfilePicId: data.ProfilePicId,
      });

      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        console.log(response.error);
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await getUser({ id });
      reset({
        Id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        Email: response.data.email,
        ProfilePicId: response.data.ProfilePicId,
        FullFileUrl: response.data.FullFileUrl,
        roleId: response.data.roleId,
        password: response.data.password,
        phoneNumber: response.data.phoneNumber,
        statusId: response?.data?.isActive ? 1 : 2,
      });

      setPhoneFields({
        ContactPhone: response.data.phoneNumber,
      });

      setLoadoing(false);
    } catch (error) {
      setLoadoing(false);
      console.log(error, "error");
    }
  };

  async function deleteData() {
    let response = await deleteUser(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate("/users");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await getAllRoles({
        pageNumber: 1,
        pageSize: 100,
      });
      if (response.statusCode === 200) {
        setRoles(
          response.data.items.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
    fetchRoles();
  }, []);

  const statusData: any[] = [
    {
      label: t("ACTIVE"),
      value: 1,
    },
    {
      label: t("INACTIVE"),
      value: 2,
    },
  ];

  const Details = [
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
    {
      name: "Jesse Thomas",
      points: "637 Points - 98% Correct",
    },
  ];

  return (
    <>
      <div className="me-4">
        <div className="top-bg my-3">
          <h6 className="px-2 pt-2 text-white-50">{t("Pages_users")}</h6>
          <span className="px-2  text-white">{t("Add_Users")}</span>
        </div>
        <form
          name="chat-form"
          id="chat-form"
          className=" roboto-thin "
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <Card.Body>
              {loading && <Loader />}
              <Row>
                <Col xl={12}>
                  <Row className="roboto-thin">
                    <Col xl={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={t("username")}
                        label={t("First_Name")}
                        showError={showError}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3 inputChange "
                        componentName="Name"
                      />
                    </Col>
                    <Col xl={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder={t("Enter_last_name")}
                        label={t("Last_Name")}
                        showError={showError}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3 inputChange"
                        componentName="Name"
                      />
                    </Col>
                    <Col xl={6}>
                      <PhoneCtrl
                        control={control}
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder={t("5xxxxxxxxxx")}
                        label={t("Phone_Number")}
                        showError={showError}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3   w-100"
                        defaultValue={phoneValue.ContactPhone}
                      />
                    </Col>

                    <Col xl={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="Email"
                        id="Email"
                        placeholder={t("usernamemail")}
                        label={t("Email")}
                        showError={showError}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3 inputChange "
                        componentName="Email"
                      />
                    </Col>
                    <Col xl={12}>
                      <SelectCtrl
                        control={control}
                        name="role"
                        id="role"
                        placeholder={t("Select")}
                        label={t("Role")}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3 inputChange"
                        options={roles.map((item) => ({
                          label: t(item.label),
                          value: item.value,
                        }))}
                        showError={showError}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
            <div className="d-flex pb-4  ">
              <div className="button-list w-100 d-flex justify-content-center align-items-baseline">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={apiStatus.inprogress}
                  className="px-4 w-50 rounded button-54"
                >
                  {t("ADD")}
                </Button>
              </div>

              <div className="float-end">
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div>
            </div>
          </Card>
        </form>
      </div>
    </>
  );
};

export default ConfigDetails;
