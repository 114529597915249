import React, { useEffect, useRef } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button } from "react-bootstrap";
import { InputCtrl, SelectCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteUser, getAllRoles, getUser, saveUser } from "../../helpers";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useToast } from "../../hooks";
import Loader from "../../components/Loader";
import PhoneCtrl from "../../components/PhoneCtrl";
import { useTranslation } from "react-i18next";
import {
  deleteTripFareRule,
  getTripFareRule,
  saveTripFareRule,
} from "../../helpers/api/farecalculation";

const defaultValues: {
  Id: number;
  baseFare: number | string;
  costPerMinTimeInRide: number | string;
  costPerMileRideDistance: number | string;
  chargeAtStop: number | string;
  serviceFee: number | string;
  tax: number | string;
} = {
  Id: 0,
  baseFare: "",
  costPerMinTimeInRide: "",
  costPerMileRideDistance: "",
  chargeAtStop: "",
  serviceFee: "",
  tax: "",
};

const FareCalculation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [roles, setRoles] = useState<any[]>([]);
  const { showToast, dissmisToast } = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();

  const [apiStatus] = useState({
    inprogress: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    Id: number;
    baseFare: number | string;
    costPerMinTimeInRide: number | string;
    costPerMileRideDistance: number | string;
    chargeAtStop: number | string;
    serviceFee: number | string;
    tax: number | string;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    Id: number;
    baseFare: number | string;
    costPerMinTimeInRide: number | string;
    costPerMileRideDistance: number | string;
    chargeAtStop: number | string;
    serviceFee: number | string;
    tax: number | string;
  }> = async (data) => {
    try {
      const response = await saveTripFareRule({
        Id: data.Id,
        baseFare: data.baseFare,
        costPerMinTimeInRide: data.costPerMinTimeInRide,
        costPerMileRideDistance: data.costPerMileRideDistance,
        chargeAtStop: data.chargeAtStop,
        serviceFee: data.serviceFee,
        tax: data.tax,
      });

      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        console.log(response.error);
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await getTripFareRule({ id });
      reset({
        Id: response.data.id,
        baseFare: response.data.baseFare,
        costPerMinTimeInRide: response.data.costPerMinTimeInRide,
        costPerMileRideDistance: response.data.costPerMileRideDistance,
        chargeAtStop: response.data.chargeAtStop,
        serviceFee: response.data.serviceFee,
        tax: response.data.tax,
      });

      setLoadoing(false);
    } catch (error) {
      setLoadoing(false);
      console.log(error, "error");
    }
  };

  async function deleteData() {
    let response = await deleteTripFareRule(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", "Success");
        navigate("/farecalculation");
      } else {
        console.log(response.message);
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  const fetchRoles = async () => {
    try {
      const response = await getAllRoles({
        pageNumber: 1,
        pageSize: 100,
      });
      if (response.statusCode === 200) {
        setRoles(
          response.data.items.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
    fetchRoles();
  }, []);

  const statusData: any[] = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "In-Active",
      value: 2,
    },
  ];

  return (
    <>
      <PageTitleBox name="FareCalculation" pageTitle={t("FareCalculation")} />
      <h3 className="fw-bold mt-3 mb-2">{t("Fare_Calculation")}</h3>
      <form name="chat-form" id="chat-form" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body>
            {loading && <Loader />}
            <Row>
              <Col xl={12}>
                <Row className="roboto-thin">
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="baseFare"
                      id="baseFare"
                      placeholder={t("Enter_basefare")}
                      label={t("Base_Fare")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      componentName="Number"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="costPerMinTimeInRide"
                      id="costPerMinTimeInRide"
                      placeholder={t("Enter_cost_per_minute")}
                      label={t("Cost_per_minute_time_in_ride")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="costPerMileRideDistance"
                      id="costPerMileRideDistance"
                      placeholder={t("Enter_cost_per_mile")}
                      label={t("Cost_per_mile_ride_distance")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="chargeAtStop"
                      id="chargeAtStop"
                      placeholder={t("Enter_time_stop")}
                      label={t("Time_at_Stop")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="serviceFee"
                      id="serviceFee"
                      placeholder={t("Enter_Service_fee")}
                      label={t("Service_fee")}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      showError={showError}
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="tax"
                      id="tax"
                      placeholder={t("Enter_Tax")}
                      label={t("Tax")}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      showError={showError}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="d-flex justify-content-center align-items-baseline">
              <div className="button-list  w-75 d-flex justify-content-between  align-items-baseline">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={apiStatus.inprogress}
                  className="px-4 w-50 rounded button-54"
                >
                  {t("Save")}
                </Button>
                <Button
                  variant="outline-secondary"
                  className="px-4  w-50 rounded ms-3 py-2"
                >
                  {t("Cancel")}
                </Button>
              </div>
              <div>
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </form>
    </>
  );
};

export default FareCalculation;
