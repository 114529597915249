import { useEffect, useState } from "react";
import { Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SalesOverviewChart from "../dashboard/joudma-dashboard/SalesOverviewChart";
import { getdashboard } from "../../helpers/api/dashboard";
import DropDownFilter from "../../components/DropDownFilter";
import Loader from "../../components/Loader";
const Report = () => {
 
  const [details, setDetails] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [monthId,setMonthId]=useState<number>(new Date().getMonth() + 1)
  const months = [
    { label: t("January"), 
      value: 1 },
    { label: t("February"),
       value: 2 },
    { label: t("March"),
       value: 3 },
    { label: t("April"),
       value:4},
    { label: t("May"), 
      value:5 },
    { label: t("June"), 
      value: 6 },
    { label: t("July"),
       value: 7 },
    { label: t("August"), 
      value: 8},
    { label: t("September"),
       value:9 },
    { label: t("October"),
       value: 10 },
    { label: t("November"),
       value: 11 },
    { label: t("December"),
       value: 12 },
  ];
  const handleMonthChange = (selectedQuery: {
    label: string;
    value: number;
  }) => {
    setMonthId(selectedQuery.value);
    fetchdata();
  };

  const fetchdata = async () => {
    try {
      setLoading(true)
      const params: any = { type: "SALES", filters: "MONTH" };

        params.month = monthId;
      
      const response = await getdashboard(params);
      if (response?.statusCode === 200) {
        setDetails(response?.data);
        setLoading(false)
      }
    } catch (error) {
      console.log(error, "error");
    }
  };



  useEffect(() => {
    fetchdata();
  }, [monthId]);


  const cardsData = [
    { title: t("Revenue_Generated")
      , value: details?.revenue },
    {
      title: t("Transaction_fees"),
      value: details?.transactionFees,
      description: t("per_ride"),
    },
    {
      title: t("Refunds_and_Discounts"),
      value: details?.discounts,
      description: t("discounts_applied"),
    },
  ];
  return (
    <>

      <Row>
      <div className="d-flex mt-3 mb-1 step-select">
      <DropDownFilter
          options={months?.map((item: any) => {
            return {
              label: item.label,
              value: item.value,
            };
          })}
          paramName="query"
          onChange={handleMonthChange}
          placeholderName={t("Select_Month")}
        />
      </div>
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex flex-column align-items-center ">
                <h2 className="theme-color fw-bold m-0">{t("Sales_Overview")}</h2>
                <h4 className="theme-color ">
                  (+5)
                  <span className="text-secondary fw-normal ms-1">{t("more_in_2024")}</span>
                </h4>
              </div>
              <SalesOverviewChart filter={"MONTH"} monthId={monthId}/>

            </Card.Body>
          </Card>
        </Col>
     </Row>
   
     <Row className="row-cols-md-3 row-cols-sm-3  row-cols-1  gx-3">
        {cardsData?.map((item: any, index: number) => (
          <Col key={index}>
            <Card className="customcard">
              
              <Card.Body>
              {loading? <Loader/>:(
<>
<h5 className="fw-bold theme-color ">{item?.title}</h5>
                <h6 className="fw-bold">
                  ${item?.value} {item?.description}
                </h6>
                <div className="progress-show">
                  <ProgressBar
                    variant="primary"
                    now={75}
                    style={{ height: 5 }}
                  />
                </div></>
              ) }

          
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      
    </>
  );
};

export default Report;
