export const ar = {
  translation: {
    login: "تسجيل الدخول",
    signin: "تسجيل الدخول",
    signout:"تسجيل الخروج",
    welcome: "مرحبًا بعودتك",
    Email: "بريد إلكتروني",
    password: "كلمة المرور",
    Users: "المستخدمين",
    Sr_No:"رقم مسلسل",
    Name: "اسم",
    Phone_Number: "رقم التليفون",
    role: "دور",
    ID: "بطاقة الهوية",
    add: "يضيف",
    ADD: "يضيف",
    USERS: "المستخدمين",
    HOME:"المنزل",
    Management:"إدارة",
    Pages:"صفحات",
    Rules: "قواعد",
    Pricing:"التسعير",
    search: "يطلب",
    Rides: "ركوب الخيل",
    Awards: "جائزة",
    Snippets: "مقتطفات",
    Configuration: "تكوين",
    VehicleType: "نوع السيارة",
    DriverPerformance: "أداء السائق",
    FleetTracking: "تتبع الأسطول",
    Reports: "التقارير",
    Approvals: "الموافقات",
    Achievements: "الإنجازات",
    Partners: "الشركاء",
    FareCalculation:"حساب الأجرة",
    User: "مستخدم",
    USER:"مستخدم",
    username:"اسم المستخدم",
    usernamemail:"اسم المستخدم@example.com",
    Payment: "قسط",
    Customer: "عميل",
    Pickup: "يلتقط",
    Drop: "يسقط",
    Driver_Name: "اسم السائق",
    Customer_Name: "اسم الزبون",
    Vehicle_Id: "رقم المركبة",
    Current_Location: "الموقع الحالي",
    Drop_Location: "إسقاط الموقع",
    Ride_Duration: "مدة الرحلة",
    Distance: "مسافة",
    Status: "حالة",
    No_Data_Found: "لاتوجد بيانات!",
    RIDES: "ركوب ",
    TOTAL_RIDES: "إجمالي الرحلات",
    Active_Rides:"الرحلات النشطة",
    RUNNING_RIDES: "الرحلات قيد التشغيل",
    COMPLETED_RIDES: "الرحلات المكتملة",
    CANCELLED_RIDES: "الرحلات الملغاة",
    Peak_Time_Of_Rides: "وقت الذروة لركوب الخيل",
    MORNING: "الصباح",
    AFTERNOON: "الظهيرة",
    EVENING: "المساء",
    Title: "العنوان",
    Promocode: "رمز الترويج",
    Usage_Count: "عدد الاستخدامات",
    Expiry_Date: "تاريخ الانتهاء",
    REWARDS: "المكافآت",
    TOTAL_AWARDS: "إجمالي الجوائز",
    ACTIVE_AWARDS: "الجوائز النشطة",
    EXPIRED_AWARDS: "الجوائز المنتهية",
    Add_Award: "إضافة جائزة",
    PARTNERS: "الشركاء",
    Select:"اختر",
    Partner_Name: "اسم الشريك",
    URL_of_the_partners_websiteapp:
      "عنوان URL لموقع الويب/التطبيق الخاص بالشريك",
    Logo: "شعار",
    Gender:"الجنس",
    Level: "المستوى",
    Minimum_Trips: "الرحلات الدنيا",
    Reward_Amount: "مبلغ الجائزة",
    ACHIEVEMENTS: "الإنجازات",
    Achievement_rules: "الإنجازات للسائقين بناءً على القواعد",
    Car_Make:"ماركة السيارة",
    Male:"ذكر",
    Female:"أنثى",
    Other:"آخر",
    Todays_Money: "أموال اليوم",
    Active_Drivers:  "السائقون النشطون" ,
    Today_Trips:    "رحلات اليوم",
    Todays_Users: "مستخدمو اليوم" ,
    Sales_Overview:  "نظرة عامة على المبيعات",
    more_in_2024: "إضافية في عام 2024",
    than_last_week:"من الأسبوع الماضي",
    Live_MAP:"الخريطة الحية",
    See_all_users_and_trips: "عرض جميع المستخدمين والرحلات",
    Full_Name:"الاسم الكامل",
    Created:"تم الإنشاء",
    Add_Users:"إضافة مستخدمين",
    Vehicle_Type: "نوع المركبة",
    Type_of_Vehicle: "نوع المركبة",
    Vehicle_R_No: "رقم المركبة",
    Driver_Licence: "رخصة القيادة",
    Vehicle_Insurance: "تأمين المركبة",
    Car_Model: "طراز السيارة",
    Image: "صورة",
    Ride_Id:"معرّف الرحلة",
    Base_Fare: "الاجرة الاساسية",
    Enter_basefare:"أدخل قاعدة",
    Cost_per_minute_time_in_ride: "التكلفة_لكل_دقيقة_وقت_في_الرحلة ",
    Enter_cost_per_minute:"أدخل_تكلفة_الدقيقة",
    Cost_per_mile_ride_distance:"التكلفة لكل ميل المسافة المقطوعة",
    Enter_cost_per_mile:"أدخل_التكلفة_لكل_ميل",
    Time_at_Stop:"توقف الوقت",
    Enter_time_stop:"أدخل_الوقت_توقف",
    Service_fee:"رسوم الخدمة",
    Enter_Service_fee:"أدخل_رسوم_الخدمة",
    Tax:"ضريبة",
    Enter_Tax:"أدخل_الضريبة",
    Active:"نشيط",
    In_Active:"غير نشط",
    Admin:"مسؤل",
    ADMIN:"مسؤول",
    Logout:"تسجيل الخروج",
    Driver:"سائق",
    DRIVER:"سائق",
    Page:"صفحة",
    pageIndex:"واحد",
    1:"واحد",
    of:"ل",
    Reject:"يرفض",
    Rejected: 'مرفوض',
    Approve:"يعتمد",
    Approved: "موافق",
    Table:"طاولة",
    Map:"خريطة",
    Price:"سعر",
    Enter_Price:"أدخل_السعر",
    Enter_Discount:"أدخل_الخصم",
    Discount:"تخفيض",
    Latest_Added:"أحدث إضافة",
    DRIVER_APPROVALS: "موافقات السائق",
    REPORTS: "التقارير",
    Revenue_Generated: "الإيرادات المُولّدة:",
    Transaction_fees: "رسوم المعاملات",
    Refunds_and_Discounts: "المستردات والخصومات",
    Select_Month: "اختر الشهر",
    Select_State:"اختر الولاية",
    Select_City:"اختر المدينة",
    Fare_Calculation: "حساب الأجرة",
    Total_Earning: "الأرباح الإجمالية",
    Revenue: "الإيراد",
    per_ride:"لكل رحلة",
    discounts_applied:"تم تطبيق الخصومات",
    Select_Country:"اختر الدولة",
    FLEET_TRACKING: "تتبع الأسطول",
    Available: "متاح",
    Ride_Started: "بدء الرحلة",
    Reached_Pickup: "وصول لموقع الالتقاط",
    Enroute_to_Pickup: "في الطريق إلى موقع الالتقاط",
    All: "الكل",
    ALL: "الكل",
    Map: "الخريطة",
    Setllite: "الأقمار الصناعية",
    ADD_NEW: "أضف جديد",
    AM_to_9AM:"من 8 صباحًا إلى 9 صباحًا",
    AM_to_2PM:"من 12 صباحًا إلى 2 ظهرًا",
    PM_to_8PM:"من 6 مساءً إلى 8 مساءً",
    Search_here:"ابحث هنا...",
    Pages_users:"صفحات/مستخدمين",
    Field_is_required:"هذا الحقل مطلوب",
    Vehicle_Number: "رقم المركبة",
    Ratings: "التقييمات",
    Earnings: "الأرباح",
    DRIVER_PERFORMANCE: "أداء السائق",
    icon: "أيقونة",
    PARTNER_DETAILS: "تفاصيل الشريك",
    Vehicle_Name: "اسم المركبة",
    Save: "حفظ",
    Cancel: "إلغاء",
    Upload_File: "تحميل الملف",
    Upload: "تحميل",
    Drop_File: "اسحب الملفات هنا أو انقر للتحميل.",
    Supported_Format: "الصيغ المدعومة",
    Enter_Vehicle_Name: "أدخل اسم المركبة",
    Updated_On: "تم التحديث في",
    Updated_By: "تم التحديث بواسطة",
    Key: "المفتاح",
    Content: "المحتوى",
    Are_you_sure: "هل أنت متأكد؟",
    First_Name: "الاسم الأول",
    Last_Name: "الاسم الأخير",
    Password: "كلمة المرور",
    Phone: "الهاتف",
    Role: "الدور",
    Enter_first_name: "أدخل الاسم الأول",
    Enter_last_name: "أدخل الاسم الأخير",
    Enter_email: "أدخل البريد الإلكتروني",
    Enter_password: "أدخل كلمة المرور",
    Please_Select: "من فضلك_اختر",
    Enter_Phone: "أدخل رقم الهاتف",
    Please_Select_roles: "يرجى تحديد الدور",
    USER_DETAILS: "تفاصيل المستخدم",
    AWARD_DETAILS: "تفاصيل الجائزة",
    Enter_title: "أدخل العنوان",
    Enter_promocode: "أدخل رمز الترويج",
    Enter_partner: "أدخل الشريك",
    Partner: "الشريك",
    Usage_Time: "وقت الاستخدام",
    Enter_usage_time: "أدخل وقت الاستخدام",
    Please_Select: "من فضلك_اختر",
    Type: "النوع",
    Enter_Expiry_Date: "أدخل تاريخ الانتهاء",
    Enter_first_title: "أدخل العنوان الأول",
    Website_Url: "عنوان موقع الويب",
    Enter_website_url: "أدخل عنوان موقع الويب",
    ACHIEVEMENT_DETAILS: "تفاصيل الإنجاز",
    Enter_level_name: "أدخل اسم المستوى",
    Enter_minimum_trips: "أدخل الرحلات الدنيا",
    Enter_amount: "أدخل المبلغ",
    Amount: "المبلغ",
    APPROVAL_DETAILS: "تفاصيل الموافقة",
    Enter_name: "أدخل الاسم",
    Enter_Phone_Number: "أدخل رقم الهاتف",
    Vehicle_Registration_No: "رقم تسجيل المركبة",
    Enter_Vehicle_Registration_No: "أدخل رقم تسجيل المركبة",
    Enter_Driver_License: "أدخل رخصة السائق",
    Driver_License: "رخصة السائق",
    Please_Select_Vehicle_Insurance: "يرجى تحديد تأمين المركبة",
    Please_Select_Model: "يرجى تحديد الطراز",
    Enter_Email: "أدخل البريد الإلكتروني",
    Dashboard: "لوحة التحكم",
    Total_Driver: "إجمالي السائقين",
    Total_User: "إجمالي المستخدمين",
    Drivers: "السائقين",
    Active_Drivers: "السائقين النشطين",
    Approved_Drivers: "السائقين المعتمدين",
    Unapproved_Drivers: "السائقين غير المعتمدين",
    Inactive_users: "المستخدمين غير النشطين",
    Active_Users: "المستخدمين النشطين",
    Total_Users: "إجمالي المستخدمين",
    Driver_Statistics: "إحصائيات السائقين",
    User_Statistics: "إحصائيات المستخدمين",
    Add_Promocode: 'إضافة رمز ترويجي',
    PROMOCODE_DETAILS: 'تفاصيل الرمز الترويجي',
    EXPIRED_PROMOCODE: 'الرمز الترويجي المنتهي',
    ACTIVE_PROMOCODE: 'الرمز الترويجي النشط',
    TOTAL_PROMOCODE: 'إجمالي الرموز الترويجية',
    Please_Select_Status: "يرجى اختيار الحالة",
    January :" يناير",
    February :" فبراير",
    March :" مارس",
    April :" أبريل",
    May :" مايو",
    June :" يونيو",
    July :" يوليو",
    August :" أغسطس",
    September :" سبتمبر",
    October :" أكتوبر",
    November :" نوفمبر",
    December :" ديسمبر",
    Add_Customer:"إضافة عميل",
    Pages_Customer:"صفحات / عميل",
    Remove:"إزالة",
    User_Family:"عائلة المستخدم",
    Profile_Information:"معلومات الملف الشخصي",
    Notification_Setting:"إعدادات الإشعارات",
    No_user_family_data_available:"لا توجد بيانات لعائلة المستخدم.",
    ACCOUNT:"حساب",
    Location:"موقع",
    Mobile_No:"رقم الهاتف المحمول",
    Email_me_when_ride_completed:"أرسل لي بريدًا إلكترونيًا عند انتهاء الرحلة",
    Email_me_when_ride_cancel:"أرسل لي بريدًا إلكترونيًا عند إلغاء الرحلة",
    Email_me_when_someone_follow_me:"أرسل لي بريداً إلكترونياً عندما يتابعني شخص ما",
    Platform_Setting:"إعدادات المنصة",
    Decisions_paragraph:"القرارات: إذا لم تتمكن من اتخاذ قرار، فالجواب هو لا. إذا كان هناك مساران صعبان بالتساوي، اختر المسار الأكثر إيلاماً على المدى القصير (تجنب الألم يخلق وهماً بالمساواة).",
    Hi_Im:"مرحباً، أنا",
    Latest_Trips:"أحدث الرحلات",
    Pages_Driver:"الصفحات / السائق",
    Add_Driver:"إضافة سائق",
    No_Latest_Trips_Available:"لا توجد رحلات حديثة متاحة",
    The_account_has_been_verified:"تم التحقق من الحساب",
    Personal_Identification:"التحقق من الهوية الشخصية" ,
    Identification_image_has_not_yet_been_uploaded_and_is_currently_pending_verification: 'صورة الهوية لم تُرفع بعد وهي قيد التحقق حاليًا',
    Insurance_License:"رخصة التأمين" ,
    Driving_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification:'صورة رخصة القيادة لم تُرفع بعد وهي قيد التحقق حاليًا',
    Insurance_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification: 'صورة رخصة التأمين لم تُرفع بعد وهي قيد التحقق حاليًا',
    Driving_License:"رخصة القيادة" ,
    Are_You_Sure_You_Want_To_Delete_this_Driver:"هل أنت متأكد أنك تريد حذف هذا السائق؟",
    Are_You_Sure_You_Want_To_Delete_this_customer:"هل أنت متأكد أنك تريد حذف هذا العميل؟",
    From_the_Date:"من التاريخ",
    date:"يوم/شهر/سنة (بأربعة أرقام)",
    To_Date:"إلى الآن",
    ACTIVE:"نشط",
    INACTIVE:"غير نشط",
    Percent:"نسبة مئوية",
    Fixed:"ثابت",
    Multiple:"متعدد",
    Single:"فردي" ,
    Value:"القيمة",
    blinkit:"بلينكيت",
    Myntra:"مينترا",
    Zomato:"زوماتو",
    Delete_The_Logo:"هل أنت متأكد أنك تريد حذف الشعار؟",
    Website_App_Url:"رابط الموقع الإلكتروني / التطبيق",
    Images:"صور",
    Driver_Performance_Details:"تفاصيل أداء السائق",
    ADD_Snippets:"إضافة مقتطفات",
    Your_payment_has_been_successfully_done:"تمت عملية الدفع بنجاح.",
    Your_payment_has_been_failed:"فشلت عملية الدفع.",
    Your_payment_is_pending:"عملية الدفع قيد الانتظار",
    Balance:"الرصيد",
    Payment_Status:"حالة الدفع",
    Ref_No:"رقم المرجع",
    Merchant_Name:"اسم التاجر",
    Payment_Method:"طريقة الدفع",
    Payment_Time:"وقت الدفع",
    succeeded:"نجح",
    failed:"فشل",
  },
};


/// ar: {
  //   translation: {
  //     login: "تسجيل الدخول",
  //     signin: "تسجيل الدخول",
  //     welcome: "مرحبًا بعودتك",
  //     Email: "بريد إلكتروني",
  //     password: "كلمة المرور",
  //     Users: "المستخدمين",
  //     Name: "اسم",
  //     Phone_Number: "رقم التليفون",
  //     role: "دور",
  //     ID: "بطاقة الهوية",
  //     add: "يضيف",
  //     USERS: "المستخدمين",
  //     search: "يطلب",
  //     Rides: "ركوب الخيل",
  //     Awards: "جائزة",
  //     Snippets: "مقتطفات",
  //     VehicleType: "نوع السيارة",
  //     DriverPerformance: "أداء السائق",
  //     FleetTracking: "تتبع الأسطول",
  //     Reports: "التقارير",
  //     Approvals: "الموافقات",
  //     Achievements: "الإنجازات",
  //     Partners: "الشركاء",
  //     User: "مستخدم",
  //     Payment: "قسط",
  //     Customer: "عميل",
  //     Pickup: "يلتقط",
  //     Drop: "يسقط",
  //     Driver_Name: "اسم السائق",
  //     Ride_Duration: "مدة الرحلة",
  //     Distance: "مسافة",
  //     Status: "حالة",
  //     No_Data_Found: "لاتوجد بيانات!",
  //     RIDES: "ركوب ",
  //     TOTAL_RIDES: "إجمالي الرحلات",
  //     RUNNING_RIDES: "الرحلات قيد التشغيل",
  //     COMPLETED_RIDES: "الرحلات المكتملة",
  //     CANCELLED_RIDES: "الرحلات الملغاة",
  //     Peak_Time_Of_Rides: "وقت الذروة لركوب الخيل",
  //     MORNING: "الصباح",
  //     AFTERNOON: "الظهيرة",
  //     EVENING: "المساء",
  //     Title: "العنوان",
  //     Promocode: "رمز الترويج",
  //     Usage_Count: "عدد الاستخدامات",
  //     Expiry_Date: "تاريخ الانتهاء",
  //     REWARDS: "المكافآت",
  //     TOTAL_AWARDS: "إجمالي الجوائز",
  //     ACTIVE_AWARDS: "الجوائز النشطة",
  //     EXPIRED_AWARDS: "الجوائز المنتهية",
  //     Add_Award: "إضافة جائزة",
  //     PARTNERS: "الشركاء",

  //     Partner_Name: "اسم الشريك",
  //     URL_of_the_partners_websiteapp:
  //       "عنوان URL لموقع الويب/التطبيق الخاص بالشريك",
  //     Logo: "شعار",

  //     Level: "المستوى",
  //     Minimum_Trips: "الرحلات الدنيا",
  //     Reward_Amount: "مبلغ الجائزة",
  //     ACHIEVEMENTS: "الإنجازات",
  //     Achievement_rules: "الإنجازات للسائقين بناءً على القواعد",

  //     Vehicle_Type: "نوع المركبة",
  //     Vehicle_R_No: "رقم المركبة",
  //     Driver_Licence: "رخصة القيادة",
  //     Vehicle_Insurance: "تأمين المركبة",
  //     Car_Model: "طراز السيارة",
  //     Image: "صورة",
  //     DRIVER_APPROVALS: "موافقات السائق",

  //     REPORTS: "التقارير",
  //     Revenue_Generated: "الإيرادات المُولّدة:",
  //     Transaction_fees: "رسوم المعاملات",
  //     Refunds_and_Discounts: "المستردات والخصومات",
  //     Select_Month: "اختر الشهر",
  //     Total_Earning: "الأرباح الإجمالية",
  //     Revenue: "الإيراد",

  //     FLEET_TRACKING: "تتبع الأسطول",
  //     Available: "متاح",
  //     Ride_Started: "بدء الرحلة",
  //     Reached_Pickup: "وصول لموقع الالتقاط",
  //     Enroute_to_Pickup: "في الطريق إلى موقع الالتقاط",
  //     All: "الكل",
  //     Map: "الخريطة",
  //     Setllite: "الأقمار الصناعية",

  //     Vehicle_Number: "رقم المركبة",
  //     Ratings: "التقييمات",
  //     Earnings: "الأرباح",
  //     DRIVER_PERFORMANCE: "أداء السائق",

  //     icon: "أيقونة",
  //     PARTNER_DETAILS: "تفاصيل الشريك",
  //     Vehicle_Name: "اسم المركبة",
  //     Save: "حفظ",
  //     Cancel: "إلغاء",
  //     Upload_File: "تحميل الملف",
  //     Upload: "تحميل",

  //     Drop_File: "اسحب الملفات هنا أو انقر للتحميل.",
  //     Supported_Format: "الصيغ المدعومة",
  //     Enter_Vehicle_Name: "أدخل اسم المركبة",

  //     Updated_On: "تم التحديث في",
  //     Updated_By: "تم التحديث بواسطة",
  //     Key: "المفتاح",

  //     Content: "المحتوى",
  //     Are_you_sure: "هل أنت متأكد؟",



  //     First_Name: "الاسم الأول",
  //     Last_Name: "الاسم الأخير",
  //     Password: "كلمة المرور",
  //     Phone: "الهاتف",
  //     Role: "الدور",
  //     Enter_first_name: "أدخل الاسم الأول",
  //     Enter_last_name: "أدخل الاسم الأخير",
  //     Enter_email: "أدخل البريد الإلكتروني",
  //     Enter_password: "أدخل كلمة المرور",
  //     Please_Select_Status: "يرجى تحديد الحالة",
  //     Enter_Phone: "أدخل رقم الهاتف",
  //     Please_Select_roles: "يرجى تحديد الدور",



  //     USER_DETAILS: "تفاصيل المستخدم",
  //     AWARD_DETAILS: "تفاصيل الجائزة",
  //     Enter_title: "أدخل العنوان",
  //     Enter_promocode: "أدخل رمز الترويج",
  //     Enter_partner: "أدخل الشريك",
  //     Partner: "الشريك",
  //     Usage_Time: "وقت الاستخدام",
  //     Enter_usage_time: "أدخل وقت الاستخدام",
  //     Please_Select_Type: "يرجى تحديد النوع",
  //     Type: "النوع",
  //     Enter_Expiry_Date: "أدخل تاريخ الانتهاء",
      

  //     Enter_first_title: "أدخل العنوان الأول",
  //     Website_Url: "عنوان موقع الويب",
  //     Enter_website_url: "أدخل عنوان موقع الويب",



  //     ACHIEVEMENT_DETAILS: "تفاصيل الإنجاز",
  //     Enter_level_name: "أدخل اسم المستوى",
  //     Enter_minimum_trips: "أدخل الرحلات الدنيا",
  //     Enter_amount: "أدخل المبلغ",
  //     Amount: "المبلغ",






  //     APPROVAL_DETAILS: "تفاصيل الموافقة",
  //     Enter_name: "أدخل الاسم",
  //     Enter_Phone_Number: "أدخل رقم الهاتف",
  //     Vehicle_Registration_No: "رقم تسجيل المركبة",
  //     Enter_Vehicle_Registration_No: "أدخل رقم تسجيل المركبة",
  //     Enter_Driver_License: "أدخل رخصة السائق",
  //     Driver_License: "رخصة السائق",
  //     Please_Select_Vehicle_Insurance: "يرجى تحديد تأمين المركبة",
  //     Please_Select_Model: "يرجى تحديد الطراز",

  //     Enter_Email: "أدخل البريد الإلكتروني",


  //     Dashboard: "لوحة التحكم",
  //     Total_Driver: "إجمالي السائقين",
  //     Total_User: "إجمالي المستخدمين",
  //     Drivers: "السائقين",
  //     Active_Drivers: "السائقين النشطين",
  //     Approved_Drivers: "السائقين المعتمدين",
  //     Unapproved_Drivers: "السائقين غير المعتمدين",
  //     Inactive_users: "المستخدمين غير النشطين",
  //     Active_Users: "المستخدمين النشطين",
  //     Total_Users: "إجمالي المستخدمين",


  //     Driver_Statistics: "إحصائيات السائقين",
  //     User_Statistics: "إحصائيات المستخدمين"


  //   },
  // },