import Select, { SingleValue } from "react-select";

interface DropDownProps {
  pagination?: any;
  options: any[];
  paramName: string;
  onChange: (selectedQuery?: any) => void;
  placeholderName: string;
  clasName?: any;
}

const DropDownFilter = ({
  pagination,
  options = [],
  paramName,
  onChange,
  placeholderName,
  clasName,
}: DropDownProps) => {
  const handleSelectChange = (
    selectedOption: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    if (pagination?.handleSelectFilter && selectedOption) {
      pagination?.handleSelectFilter(selectedOption.value || "", paramName);
    }
    onChange(selectedOption);
  };

  return (
    <div className={clasName}>
      <Select
        placeholder={placeholderName}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? "#307e8a8c "
              : "white" && state.isSelected
              ? "#307E8A"
              : "transparent",
            color: state.isSelected ? "white" : "#000",
            "&:active": {
              backgroundColor: "#307E8A",
            },
          }),
          singleValue: (provided) => ({
            ...provided,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "white",
            
          }),
        }}
        options={options}
        onChange={(selectedOption) => {
          handleSelectChange(selectedOption);
        }}
      />
    </div>
  );
};

export default DropDownFilter;
