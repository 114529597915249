import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useEffect, useState } from "react";
import {
  deleteUser,
  getUserGetDriverWithDetails,
  saveUser,
  getLatestTrip,
} from "../../helpers/api/user";
import { useNavigate, useParams } from "react-router";
import Loader from "../../components/Loader";
import { useForm } from "react-hook-form";
import { InputCtrl } from "../../controllers";
import { useTranslation } from "react-i18next";
import PhoneCtrl from "../../components/PhoneCtrl";
import { useToast } from "../../hooks";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
const defaultValues: {
  Id: number;
  firstName: string;
  lastName: string;
  Email: string;
  roleId: number | string;
  statusId: number | string;
  password: string;
  ProfilePicId: number;
  FullFileUrl: string;
  phoneNumber: number | string;
} = {
  Id: 0,
  firstName: "",
  lastName: "",
  Email: "",
  password: "",
  FullFileUrl: "",
  ProfilePicId: 0,
  statusId: 1,
  roleId: 2,
  phoneNumber: "",
};

const Driver = () => {
  const [loading, setLoadoing] = useState<boolean>(false);
  const [tripDetails, setTripDetails] = useState<any>();
  const navigate = useNavigate();
  const [driverDeatils, setDriverDetails] = useState<any>();
  const params: any = useParams();
  const [isfetchLatestTrip, setIsfetchLatestTrip] = useState<boolean>(true);
  const [isEdit, setIsEDit] = useState<boolean>(false);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [phoneValue, setPhoneFields] = useState({
    ContactPhone: "",
  });

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    id: number;
    firstName: string;
    email: string;
    roleId: number | string;
    password: string;
    lastName: string;
    phoneNumber: number | string;
    address: string;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const id = Number(params.id);
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };
  const onSubmit = async (data: any) => {
    try {
      let requestBody: any = {
        ...data,
      };
      const response = await saveUser(requestBody);

      if (response.statusCode === 200) {
        showToast("success", response.message);
        setIsEDit(false);
        GetDetails();
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetTrips = async () => {
    try {
      const response = await getLatestTrip({
        pageNumber: 1,
        pageSize: 4,
        userId: id,
      });
      if (response?.statusCode === 200) {
        setTripDetails(response?.data?.items);
        setIsfetchLatestTrip(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await getUserGetDriverWithDetails({ id });
      setLoadoing(false);
      console.log(response);
      if (response.statusCode === 200) {
        setDriverDetails(response.data);
        reset(response.data);
        setPhoneFields({
          ContactPhone: response.data.phoneNumber,
        });
      }
    } catch (error) {
      setLoadoing(false);
      console.log(error, "error");
    }
  };
  const handleCheck = async (isActive: boolean | undefined, key: string) => {
    try {
      const response = await saveUser({
        ...driverDeatils,
        [key]: isActive,
      });
      if (response?.statusCode === 200) {
        GetDetails();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (id) {
      GetDetails();
    }
    GetTrips();
  }, []);
  async function deleteData() {
    let response = await deleteUser(driverDeatils?.id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }
  const RemoveBtn = () => {
    return (
      <button className="btn remove-buttons">
        <FaMinusCircle />
        {t("Remove")}
      </button>
    );
  };
  return (
    <>
      {loading && <Loader />}
      <Card className="mt-2  customer-card">
        <Card.Header className="">
          <div className="background-image">
            <div className="pt-2 ps-2">
              <h6 className="px-2 pt-2 text-white-50 mb-1">
                {t("Pages_Driver")}
              </h6>
              <span className="px-2  text-white">{t("Add_Driver")}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100"></div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between  flex-wrap">
            <div>
              <div className="d-flex align-items-center gap-2">
                <div>
                  <img
                    src={driverDeatils?.profilePicFullUrl}
                    className="content-fit rounded-circle"
                    width={60}
                    height={60}
                    alt=""
                  />
                </div>
                <div>
                  <h5 className="fw-semibold mb-1">
                    {driverDeatils?.fullName}
                  </h5>
                  <p className="m-0 fw-normal small">{driverDeatils?.email}</p>
                </div>
              </div>
            </div>
            <div>
              <DeleteConfirm
                buttonElement={RemoveBtn}
                disabled={apiStatus.inprogress}
                confirm={() => {
                  deleteData();
                }}
                message={t("Are_You_Sure_You_Want_To_Delete_this_Driver")}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Row className="g-2  p-0 mb-2">
        <Col md={4}>
          <Card className="card-wrapper mb-1">
            <h6 className="fw-bold">{t("Notification_Setting")}</h6>
            <p className="m-0 small">{t("ACCOUNT")}</p>
            <ul className="list-unstyled p-0 my-2">
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={driverDeatils?.notifyWhenRideIsStarted}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleCheck(
                          e.target.checked,
                          "notifyWhenRideIsStarted"
                        );
                      }}
                    />
                  </Form>
                </span>
                {t("Email_me_when_someone_follow_me")}
              </li>
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={driverDeatils?.notifyWhenRideIsCancelled}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleCheck(
                          e.target.checked,
                          "notifyWhenRideIsCancelled"
                        );
                      }}
                    />
                  </Form>
                </span>
                {t("Email_me_when_ride_cancel")}
              </li>
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={driverDeatils?.notifyWhenRideIsCompleted}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleCheck(
                          e.target.checked,
                          "notifyWhenRideIsCompleted"
                        );
                      }}
                    />
                  </Form>
                </span>
                {t("Email_me_when_ride_completed")}
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("Profile_Information")}</h6>
            <p className="m-0 small text-secondary opacity-50">
              {t("Hi_Im")} {driverDeatils?.fullName}, {t("Decisions_paragraph")}
            </p>
            <hr className="my-1" />
            {isEdit ? (
              <form
                name="chat-form"
                id="chat-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col xl={12}>
                    <Row className="roboto-thin">
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder={t("username")}
                          label={t("First_Name")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-3 inputChange"
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder={t("Enter_last_name")}
                          label={t("Last_Name")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-3  inputChange"
                        />
                      </Col>
                      <Col xl={6}>
                        <PhoneCtrl
                          control={control}
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder={t("5xxxxxxxxxx")}
                          label={t("Mobile_No")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-3 inputChange w-100"
                          defaultValue={phoneValue.ContactPhone}
                        />
                      </Col>

                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="email"
                          name="email"
                          id="email"
                          placeholder={t("usernamemail")}
                          label={t("Email")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-3  inputChange"
                        />
                      </Col>

                      <InputCtrl
                        control={control}
                        type="text"
                        name="address"
                        id="address"
                        placeholder={t("Location")}
                        label={t("Location")}
                        showError={showError}
                        required={true}
                        disabled={apiStatus.inprogress}
                        className="mb-3 inputChange"
                        defaultValue={driverDeatils?.address}
                      />
                      <div className="button-list w-100 d-flex justify-content-center  align-items-baseline">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={apiStatus.inprogress}
                          className="px-4 w-50 rounded button-54"
                        >
                          {t("Save")}
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </form>
            ) : (
              <div className="d-flex  justify-content-between">
                <div className="w-75">
                  <h6 className="theme-color fw-bold">
                    {t("Full_Name")} :
                    <span className="text-dark fw-normal  ">
                      {driverDeatils?.firstName} {driverDeatils?.lastName}
                    </span>
                  </h6>
                  <h6 className="theme-color fw-bold">
                    {t("Mobile_No")} :
                    <span className="text-dark fw-normal">
                      {driverDeatils?.phoneNumber}
                    </span>
                  </h6>
                  <h6 className="theme-color fw-bold">
                    {t("Email")} :
                    <span className="text-dark fw-normal">
                      {driverDeatils?.email}
                    </span>
                  </h6>
                  <h6 className="theme-color fw-bold">
                    {t("Location")} :
                    <span className="text-dark fw-normal">
                      {driverDeatils?.address}
                    </span>
                  </h6>
                </div>

                <div>
                  <CiEdit
                    color="#307E8A"
                    size={25}
                    onClick={() => setIsEDit(true)}
                  />
                </div>
              </div>
            )}
          </Card>
        </Col>
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("Latest_Trips")}</h6>
            {isfetchLatestTrip ? (
              <Loader />
            ) : tripDetails.length === 0 ? (
              t("No_Latest_Trips_Available")
            ) : (
              tripDetails?.map((details: any, index: number) => (
                <div key={index} className="h-100 overflow-hidden">
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex">
                      <img
                        src={details?.driverImageFileFullUrl}
                        className="content-fit rounded-circle mt-1"
                        width={45}
                        height={45}
                        alt=""
                      />

                      <div className="ms-2">
                        <h4 className="fw-normal fs-5 mb-1">
                          {details?.driverName}
                        </h4>
                        <p className="small text-secondary opacity-75 m-0">
                          {details?.driverAvgRating}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="my-1" />
                </div>
              ))
            )}
          </Card>
        </Col>
      </Row>
      <Row className="g-2">
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("Driving_License")}</h6>
            <div className="d-flex align-items-center pb-1 justify-content-between">
              {
                driverDeatils?.licenseFullFileUrl ?   <p className="m-0 small">{t("The_account_has_been_verified")}</p> :  <p className="m-0 small">{t("Driving_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification")}</p>
              }
              
            </div>
            <div>
              <img
                src={driverDeatils?.licenseFullFileUrl}
                className="img-fluid license-img"
                
                alt=""
              />
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold ">{t("Insurance_License")}</h6>
            <div className="d-flex align-items-center pb-1  justify-content-between">
              {
                 driverDeatils?.insuranceFullFileUrl ?  <p className="m-0 small">{t("The_account_has_been_verified")}</p>  : <p className="m-0 small">{t("Insurance_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification")}</p>
  }
            </div>
            {
              driverDeatils?.insuranceFullFileUrl &&  <div>
              <img
                src={driverDeatils?.insuranceFullFileUrl}
                className="img-fluid  license-img  "
                alt=""
              />
            </div>
            }
            
          </Card>
        </Col>
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("Personal_Identification")}</h6>
            <div className="d-flex align-items-center  pb-1  justify-content-between">
             
              {
                driverDeatils?.identificationFullFileUrl ?   <p className="m-0 small">{t("The_account_has_been_verified")}</p> : <p className="m-0 small">{t("Identification_image_has_not_yet_been_uploaded_and_is_currently_pending_verification")}</p>
              }
            </div>
            <div>
              <img
                src={driverDeatils?.identificationFullFileUrl}
                className="img-fluid license-img  "
                alt=""
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Driver;
