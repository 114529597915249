import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Button, Alert, Row, Col } from "react-bootstrap";
import { Link, useLocation, Navigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import FeatherIcons from "feather-icons-react";
import myhome from "../../assets/images/myhome.jpg";
import newlogin from "../../assets/images/newlogin.png";
import Logo from "../../assets/images/Logo.svg";
import LogoWhite from "../../assets/images/Logo_white.svg";
// actions
import { resetAuth, loginUser } from "../../redux/actions";

// store
import { RootState, AppDispatch } from "../../redux/store";

// components
import { VerticalForm, FormInput } from "../../components/";
import Select from "react-select";

import { log } from "console";
import { FiEye, FiEyeOff } from "react-icons/fi";

export const NewLogin = (): JSX.Element => {
  interface UserData {
    email: string;
    password: string;
  }
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const nevigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const languageOptions = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Arabic",
      value: "ar",
    },
  ];
  // const onSubmit = async (formData: any) => {
  //   console.log(formData)
  //   // const response = await login({email:"admin@gmail.com", password: "123456"});
  //   dispatch(loginUser("admin@yopmail.com", "123456"));
  //   nevigate('/users')
  //   // console.log(response);
  // };

  const onSubmit = async (formData: UserData) => {
    // const response = await login({email:"admin@gmail.com", password: "123456"});
    dispatch(loginUser(formData["email"], formData["password"]));
    // console.log(response);
  };

  const { user, userLoggedIn, loading, error } = useSelector(
    (state: RootState) => ({
      user: state.Auth.user,
      loading: state.Auth.loading,
      error: state.Auth.error,
      userLoggedIn: state.Auth.userLoggedIn,
    })
  );

  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup.string().required(t("Please enter Email")),
      password: yup.string().required(t("Please enter Password")),
      // checkbox: yup.bool().oneOf([true]),
    })
  );

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
  };

  // const location = useLocation();
  // const redirectUrl = location?.search?.slice(6) || "/";
  const redirectUrl = "/users";
  return (
    <>
      {userLoggedIn || user ? <Navigate to={redirectUrl}></Navigate> : null}
      <div className="container-fluid h-100">
        <div className="row text-start   p-2 justify-content-center align-items-center">
<div className="col-md-6  d-flex align-items-center   flex-column justify-content-between" style={{ height: '90vh' }}>
            {/* logo */}
            <div className="d-flex align-items-center w-75 gap-2">
              <img className="img-fluid logo" loading="eager" alt="" src={Logo} />
              <div>
                <span className="taxi fs-3">Joud</span>
                <span className="taxi fs-3">ma</span>
              </div>
            </div>
            {/* form */}
            <div className="text-start w-75">
            <div className="text-start">
                <h1 className="sign-up">{t("signin")}</h1>
                <h3 className="create-an-account pb-3 pt-2">{t("welcome")}</h3>
              </div>
              <div className="form position-relative">
                <VerticalForm<UserData>
                  onSubmit={onSubmit}
                  resolver={schemaResolver}
                  defaultValues={{ email: "", password: "" }}

                >
                  <FormInput
                    type="email"
                    name="email"
                
                    placeholder={t("Email")}
                    containerClass={"mb-3"}
                    className="border rounded "
                  />

                  <FormInput
                    type={inputType}
                    name="password"
             
                    className=" border rounded position-relative"
                    
                    placeholder={t(" Password")}
                    containerClass={"mb-3"}

                  ></FormInput>
                  <div>

                  {inputType === "password" ? (
                    // <div
                    //   className={"position-absolute top-50 end-0 translate-middle-y me-2"}
                    //   onClick={() => setInputType("text")}
                    // >
                    <FiEyeOff className="position-absolute me-2 eye-icon-custom"
                    onClick={() => setInputType("text")} />
                    // </div>
                  ) : (
                    <div
                    className={"position-absolute me-2 eye-icon-custom"}
                    onClick={() => setInputType("password")}
                    >
                    <FiEye />
                  </div>
                )}
                </div>
                  
            
      
                  <div className="d-flex justify-content-between ">
                    <div>
                      <FormInput
                        type="checkbox"
                        name="checkbox"
                        label={t("Keep me logged in")}
                        containerClass={"mb-3"}

                        defaultChecked
                      />

                    </div>

                    <div className="">
                      <Link
                        to="/auth/forget-password"
                        className=" hassle-free ms-1"

                      >
                        {t("Forgot your password?")}
                      </Link>
                    </div>

                   
                  </div>




                  <div className="button-54 ">
                    <Button
                      style={{ width: "100%" }}
                      className="button-54"
                      type="submit"
                      disabled={loading}
                    >
                      {t("Continue")}
                    </Button>
                  </div>
                </VerticalForm>
              </div>
            </div>
            <div>

            </div>
          </div>

          <div className="col-md-6 right_side ">
            <img src={LogoWhite} className="img-fluid" alt="" />
            <div className="lang-sel custome-div  ">
              <Select
                options={languageOptions}
                defaultValue={languageOptions.find((option) => option.value === "en")}
                onChange={(e: any) => {
                  changeLanguage(e.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
