import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { ISelectOption, ISelectProps } from "../interfaces";
import Select, { StylesConfig } from 'react-select';
import { ControlProps, MenuProps } from 'react-select';

const SelectCtrl = ({
	control,
	showError,
	placeholder,
	name,
	required,
	disabled,
	id,
	startAdornment,
	endAdornment,
	startAdornmentIcon,
	endAdornmentIcon,
	label,
	options,
    onSelect,
	className = '',
}: ISelectProps) => {
	const rules: any = {
		required: required,
		pattern: {
			value: /[A-Za-z0-9]{1,20}/,
			message: "Field is invalid",
		},
	};
    const onSelectHandler = (selectedOption: ISelectOption) => { 
        if (onSelect) { 
            onSelect(selectedOption)
        }
    } 
	// const _styles: any = {
	// 	option: (provided: any, state: any) => ({
	// 		...provided,
	// 		backgroundColor: state.isFocused || state.isSelected ? '#307E8A' : provided.backgroundColor,
	// 		color: state.isFocused || state.isSelected ? '#fff' : provided.color, // Set background color of dropdown menu
	// 	}),
	//   }

	const _styles: any = {
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isFocused || state.isSelected ? '#307e8a8c' : provided.backgroundColor,
			color: state.isFocused || state.isSelected ? 'white' : provided.color, // Set background color of dropdown menu
		
			'&:active': {
				backgroundColor: '#307e8a8c',
				color:"white"
			},
		}),

		 
	  }
	
	return (
		<Controller
			rules={rules}
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<>
					<Form.Group className={`mb-3 ${className}`}>
						<Form.Label htmlFor={id} className={`${label === '' ? 'd-none': ''}`}>
							{label}{" "}
							{required && <span className="text-danger">*</span>}
						</Form.Label>
						<Select
							{...field}
							inputId={id} 
							isDisabled={disabled}
							placeholder={placeholder}
							options={options}
							value={options.find((o) => o.value == field.value)}
							onChange={(selectedOption:any) => {
								if(selectedOption){
									field.onChange(selectedOption.value);
									onSelectHandler(selectedOption);
								}
							}}
							styles={_styles}
						/>
						{showError && showError(name) ? (
							<Form.Control.Feedback
								type="invalid"
								className="d-block"
							>
								{showError(name)}
							</Form.Control.Feedback>
						) : null}
					</Form.Group>
				</>
			)}
		></Controller>
	);
};

export default SelectCtrl;
