import React, { useEffect } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row } from "react-bootstrap";
import { InputCtrl } from "../../controllers";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { getDriver } from "../../helpers/api/driverPerformance";

//Default values
const defaultValues: {
  id: number;
  fullName: string;
  phoneNumber: any;
  vehicleType: string;
  registrationNumber: any;
  avgRating: any;
  totalEarnings: any;
} = {
  id: 0,
  fullName: "",
  phoneNumber: "",
  vehicleType: "",
  registrationNumber: "",
  avgRating: "",
  totalEarnings: "",
};

const PricingDetails = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const { showToast } = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    id: number;
    fullName: string;
    phoneNumber: any;
    vehicleType: string;
    registrationNumber: any;
    avgRating: any;
    totalEarnings: any;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await getDriver({ id });

      reset({
        id: response.data.id,
        fullName: response.data.fullName,
        phoneNumber: response.data.phoneNumber,
        vehicleType: response.data.vehicleType,
        registrationNumber: response.data.vehicle.items[0].registrationNumber,
        avgRating: response.data.avgRating,
        totalEarnings: response.data.totalEarnings,
      });
      setLoadoing(false);
    } catch (error) {
      setLoadoing(false);
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);

  return (
    <>
      <h3 className="fw-bold mt-3 mb-2">{t("Driver_Performance_Details")}</h3>
      <form name="chat-form" id="chat-form" className=" w-100    roboto-thin ">
        <Card className="me-3">
          <Card.Body>
            {loading && <Loader />}
            <Row className="p-2">
              <Col xl={12}>
                <Row className="roboto-thin ">
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder={t("Username")}
                      label={t("Name")}
                      showError={showError}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      // componentName="Number"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder={t("5xxxxxxxxx")}
                      label={t("Phone_Number")}
                      showError={showError}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      // componentName="Number"
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="vehicleType"
                      id="vehicleType"
                      placeholder={t("VehicleType")}
                      label={t("Type_of_Vehicle")}
                      showError={showError}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      // componentName="Number"
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name= "registrationNumber"
                      id= "registrationNumber"
                      placeholder={t("5xxxxxxxxx")}
                      label={t("Vehicle_Number")}
                      showError={showError}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      // componentName="Number"
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="avgRating"
                      id="avgRating"
                      placeholder={t("Rating")}
                      label={t("Ratings")}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      showError={showError}
                    />
                  </Col>

                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="totalEarnings"
                      id="totalEarnings"
                      placeholder={t("Earning")}
                      label={t("Earnings")}
                      required={true}
                      disabled={true}
                      className="mb-3 inputChange"
                      showError={showError}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-baseline "></div>
          </Card.Body>
        </Card>
      </form>
    </>
  );
};

export default PricingDetails;
