import { Card, Col, Row } from "react-bootstrap";
import { useApi, useToast } from "../../hooks";
import Table from "../../components/Table";
import AwardStasProps from "../awards/AwardStasCard";
import { getAllRides, getTripStats } from "../../helpers/api/ride";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Search from "../../components/Search";
import { FormatCurrency } from "../../common/utility";

const Ride = () => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [isTimeChange, setIsTimeChange] = useState(false);
  const [trip, setTrip] = useState<any>([]);
  const [time, setTime] = useState({
    start: "00:00",
    end: "23:59",
  });
  const [active, setActive] = useState<any>();
  const columns = [
    {
      Header: t("ID"),
      accessor: "id",
    },
    {
      Header: t("User"),
      accessor: "customerName",
    },
    {
      Header: t("Payment"),
      accessor: (row: any) => {
        return FormatCurrency(row.estimateCharges) || "NA";
      },
    },

    {
      Header: t("Pickup"),
      accessor: "startAddress",
    },
    {
      Header: t("Drop"),
      accessor: "endAddress",
    },

    {
      Header: t("Driver_Name"),
      accessor: "driverName",
    },

    {
      Header: t("Ride_Duration"),
      accessor: (row: any) => {
        return (
          (
            <p className="mb-0 ">
              {" "}
              {row.actualDuration} <span>Min</span>
            </p>
          ) || "NA"
        );
      },
    },

    {
      Header: t("Distance"),
      accessor: (row: any) => <span>{row.distance} Km</span>,
    },
    {
      Header: t("Status"),
      accessor: (row: any) => {
        return (
          (
            <div
              className={`text-light  px-1 rounded-3 text-center ${
                row.status === "ACTIVE"
                  ? "status-color"
                  : row.status === "REQUESTED"
                  ? "bg-warning"
                  : row.status === "ENDED"
                  ? "bg-secondary"
                  : row.status === "COMPLETE"
                  ? "bg-secondary"
                  : row.status === "ACCEPTED"
                  ? "bg-info"
                  : "bg-danger"
              }`}
            >
              {row.status === "ACTIVE" ? (
                <span>ACTIVE</span>
              ) : row.status === "ENDED" ? (
                <span>COMPLETED</span>
              ) : row.status === "COMPLETE" ? (
                <span>COMPLETED</span>
              ) : row.status === "REQUESTED" ? (
                <span>REQUESTED</span>
              ) : row.status === "ACCEPTED" ? (
                <span>ACCEPTED</span>
              ) : (
                <span>CANCELED</span>
              )}
            </div>
          ) || "NA"
        );
      },
    },
  ];

  const {
    list: partnerList,
    isFetching,
    pagination,
    reCall,
  } = useApi(
    "GetAllRides",
    (data: any) =>
      getAllRides({
        pageNumber: 1,
        pageSize: 10,
        startTime: time?.start,
        endTime: time?.end,
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );

  const updateTime = (type: string, start: string, end: string) => {
    setIsTimeChange(true);
    setActive(type);
    setTime({
      start: start,
      end: end,
    });
  };

  useEffect(() => {
    if (isTimeChange) {
      reCall();
    }
  }, [time]);

  const TripStats = async () => {
    try {
      const response = await getTripStats({});

      if (response.statusCode === 200) {
        setTrip(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    TripStats();
  }, []);
  const cardData = [
    {
      title: t("TOTAL_RIDES"),
      value: trip?.totalTrips,
      icon: "./Wallet.svg",
    },
    {
      title: t("Active_Rides"),
      value: trip?.activeTrips,
      icon: "./TotalRides.svg",
    },
    {
      title: t("COMPLETED_RIDES"),
      value: trip?.completedTrips,
      icon: "./CompletedRides.svg",
    },
    {
      title: t("CANCELLED_RIDES"),
      value: trip?.cancelledTrips,
      icon: "./CancelRide.svg",
    },
  ];

  return (
    <>
      <Row className="row-cols-md-4 row-cols-sm-2 row-cols-1 mt-3 gx-3">
        {cardData.map((item: any, index: number) => (
          <Col key={index}>
            <Card className="customcard">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="theme-color mb-1 fw-bold">{item?.title}</h5>
                    <h5 className="fw-bold mb-0">{item?.value}</h5>
                  </div>
                  <div>
                    <div className="icon-background">
                      <img src={item?.icon} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div>
        <h5 className="fw-bold">{t("Peak_Time_Of_Rides")}</h5>
        <Row className="row-cols-md-3 row-cols-sm-3  row-cols-1  gx-3">
          <Col onClick={() => updateTime("MORNING", "08:00", "09:00")}>
            <AwardStasProps
              title={t("MORNING")}
              stats={t("AM_to_9AM")}
              backgroundcol={active === "MORNING" ? "status-color" : ""}
            />
          </Col>

          <Col onClick={() => updateTime("AFTERNOON", "12:00", "14:00")}>
            <AwardStasProps
              title={t("AFTERNOON")}
              stats={t("AM_to_2PM")}
              backgroundcol={active === "AFTERNOON" ? "status-color" : ""}
            />
          </Col>
          <Col onClick={() => updateTime("EVENING", "18:00", "20:00")}>
            <AwardStasProps
              title={t("EVENING")}
              stats={t("PM_to_8PM")}
              backgroundcol={active === "EVENING" ? "status-color" : ""}
            />
          </Col>
        </Row>
      </div>

      <Card>
        <Card.Body>
          <Search pagination={pagination} width={"w-100"} />
          <Table
            isFetching={isFetching}
            columns={columns}
            data={partnerList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            isSelectable={true}
            {...pagination}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Ride;
