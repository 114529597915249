export const en = {
  translation: {
    login: "Log In",
    signin: "Sign In",
    signout: "Sign Out",
    welcome: "Welcome back",
    Email: "Email",
    password: "Password",
    Users: "Users",
    Name: "Name",
    Sr_No: "Sr. No",
    Phone_Number: "Phone Number",
    role: "Role",
    ID: "ID",
    add: "Add",
    ADD: "ADD",
    USERS: "USERS",
    HOME: "Home",
    Pages: "Pages",
    Management: "Management",
    search: "Search..",
    Rides: "Rides",
    Rules: "Rules",
    Awards: "Awards",
    Pricing: "Pricing",
    Snippets: "Snippets",
    VehicleType: "Vehicle Type",
    Type_of_Vehicle: "Type of Vehicle",
    Fare_Calculation: "Fare Calculation",
    DriverPerformance: "Driver Performance",
    Configuration: "Configuration",
    FleetTracking: "Fleet Tracking",
    FareCalculation: " Fare Calculation",
    Base_Fare: "Base Fare",
    Enter_basefare: "Enter basefare",
    Cost_per_minute_time_in_ride: "Cost per minute time in ride",
    Enter_cost_per_minute: "Enter cost per minute",
    Cost_per_mile_ride_distance: "Cost per mile ride distance",
    Enter_cost_per_mile: "Enter cost per mile",
    Time_at_Stop: "Time at Stop",
    Enter_time_stop: "Enter time stop",
    Service_fee: "Service fee",
    Enter_Service_fee: "Enter Service fee",
    Tax: "Tax",
    Enter_Tax: "Enter Tax",
    Active: "Active",
    In_Active: "In-Active",
    Page: "Page",
    pageIndex: "pageIndex",
    Ride_Id: "Ride Id",
    Reports: "Reports",
    Approvals: "Approvals",
    Achievements: "Achievements",
    Partners: "Partners",
    User: "User",
    USER: "USER",
    Payment: "Payment",
    Customer: "Customer",
    Pickup: "Pickup",
    Drop: "Drop",
    Driver_Name: "Driver Name",
    Customer_Name: "Customer Name",
    Vehicle_Id: "Vehicle Id",
    Current_Location: "Current Location",
    Drop_Location: "Drop Location",
    Ride_Duration: "Ride Duration",
    Distance: "Distance",
    Status: "Status",
    No_Data_Found: "No Data Found!",
    RIDES: "RIDES",
    TOTAL_RIDES: "Total Rides",
    RUNNING_RIDES: "RUNNING RIDES",
    COMPLETED_RIDES: "Completed Rides",
    CANCELLED_RIDES: "Cancelled Rides",
    Active_Rides: "Active Rides",
    Peak_Time_Of_Rides: "Peak Time Of Rides",
    MORNING: "MORNING",
    AFTERNOON: "AFTERNOON",
    EVENING: "EVENING",
    Add_Users: "Add Users",
    username: "username",
    Title: "Title",
    Promocode: "Promocode",
    Usage_Count: "Usage Count",
    Expiry_Date: "Expiry Date",
    REWARDS: "REWARDS",
    TOTAL_AWARDS: "Total Awards",
    ACTIVE_AWARDS: "Active Awards",
    EXPIRED_AWARDS: "Expired Awards",
    Add_Award: "Add Reward",
    PARTNERS: "PARTNERS",
    Partner_Name: "Partner Name",
    URL_of_the_partners_websiteapp: "URL of the partner's website/app",
    Logo: "Logo",
    Pages_users: "Pages / users",
    usernamemail: "username@example.com",
    Level: "Level",
    Minimum_Trips: "Minimum Trips",
    Reward_Amount: "Reward Amount",
    ACHIEVEMENTS: "ACHIEVEMENTS",
    Achievement_rules: "Achievement are for driver based on rules",
    Todays_Money: "Today’s Money",
    Todays_Users: "Today's Users",
    Today_Trips: "Today's Trips",
    Active_Drivers: "Active Drivers",
    Sales_Overview: "Sales Overview",
    more_in_2024: "more in 2024",
    than_last_week: "than last week",
    Live_MAP: "Live MAP",
    See_all_users_and_trips: "See all users and trips",
    Full_Name: "Full Name",
    Created: "Created",
    AM_to_9AM: "8 AM To 9 AM",
    AM_to_2PM: "12 AM To 2 PM",
    PM_to_8PM: "6 PM To 8 PM",
    Search_here: "Search here...",
    Car_Make: "Car Make",
    Vehicle_Type: "Vehicle Type",
    Vehicle_R_No: "Vehicle Reg.",
    Driver_Licence: "Driver Licence",
    Vehicle_Insurance: "Vehicle Insurance",
    Car_Model: "Car Model",
    Image: "Image",
    DRIVER_APPROVALS: " DRIVER APPROVALS",
    From_the_Date: "From the Date",
    date: "mm/dd/yyyy",
    To_Date: "To Date",
    REPORTS: "REPORTS",
    Revenue_Generated: "Revenue Generated:",
    Transaction_fees: "Transaction fees",
    Refunds_and_Discounts: "Refunds and Discounts",
    Select_Month: "Select Month",
    Total_Earning: "Total Earning",
    Revenue: "Revenue",
    discounts_applied: "discounts applied",
    per_ride: "per ride",

    FLEET_TRACKING: "FLEET TRACKING",
    Available: "Available",
    Ride_Started: "Ride Started",
    Reached_Pickup: "Reached Pickup",
    Enroute_to_Pickup: "Enroute to Pickup",
    All: "All",
    ALL: "ALL",
    Map: "Map",
    ADD_NEW: "ADD NEW",
    Setllite: "Setllite",
    Vehicle_Number: "Vehicle Number",
    Ratings: "Ratings",
    Earnings: "Earnings",
    DRIVER_PERFORMANCE: "DRIVER PERFORMANCE",
    1: "1",
    of: "of",
    Reject: "Reject",
    Rejected: "Rejected",
    Approve: "Approve",
    Approved: 'Approved',
    Table: "Table",
    Map: "Map",
    Price: "Price",
    Enter_Price: "Enter Price",
    Enter_Discount: "Enter Discount",
    Discount: "Discount",
    Select_Country: "Select Country",
    Select_State: "Select State",
    Select_City: "Select City",
    icon: "icon",
    PARTNER_DETAILS: "PARTNER DETAILS",
    Vehicle_Name: "Vehicle Name",
    Save: "Save",
    Cancel: "Cancel",
    Upload_File: "Upload File",
    Upload: "Upload",
    ADD_Snippets: "ADD Snippets",
    Drop_File: "Drop files here or click to upload.",
    Supported_Format: "Supported Format",
    Enter_Vehicle_Name: "Enter Vehicle Name",
    Your_payment_has_been_successfully_done: "Your payment has been successfully done.",
    Your_payment_has_been_failed: "Your payment has been failed.",
    Your_payment_is_pending: "Your payment is pending.",
    Balance: "Balance",
    Payment_Status: "Payment Status",
    Ref_No: "Ref No",
    Merchant_Name: "Merchant Name",
    Payment_Method: "Payment Method",
    Payment_Time: "Payment Time",
    succeeded: "succeeded",
    failed: "failed",

    Updated_On: "Updated On",
    Updated_By: "Updated_By",
    Key: "Key",
    Latest_Trips: "Latest Trips",
    Content: "Content",
    Are_you_sure: "Are you sure ?",
    Pages_Driver: "Pages / Driver",
    Add_Driver: "Add Driver",
    No_Latest_Trips_Available: "No Latest Trips Available",
    The_account_has_been_verified: "The account has been verified",
    Personal_Identification: "Personal Identification",
    Insurance_License: "Insurance License",
    Driving_License: "Driving License",
    Are_You_Sure_You_Want_To_Delete_this_Driver: "Are You Sure, You Want To Delete this Driver?",
    Are_You_Sure_You_Want_To_Delete_this_customer: "Are You Sure, You Want To Delete this customer?",
    Insurance_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification: 'Insurance license image has not yet been uploaded and is currently pending verification',
    Driving_license_image_has_not_yet_been_uploaded_and_is_currently_pending_verification: 'Driving license image has not yet been uploaded and is currently pending verification',
    Identification_image_has_not_yet_been_uploaded_and_is_currently_pending_verification: 'Identification image has not yet been uploaded and is currently pending verification',
    Images: "Images",
    Driver_Performance_Details: "Driver Performance Details",
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    Password: 'Password',
    Phone: 'Phone',
    Role: 'Role',
    Enter_first_name: 'Enter first name',
    Enter_last_name: 'Enter last name',
    Enter_email: 'Enter email',
    Enter_password: 'Enter password',
    Please_Select: 'Please Select',
    Enter_Phone: 'Enter Phone',
    Please_Select_role: 'Please Select role',
    Admin: 'Admin',
    Driver: 'Driver',
    DRIVER: "DRIVER",
    Logout: "Logout",
    ADMIN: "ADMIN",
    Gender: "Gender",
    Male: "Male",
    Female: "Female",
    Other: "Other",
    USER_DETAILS: 'USER DETAILS',
    AWARD_DETAILS: 'REWARD DETAILS',
    Enter_title: 'Enter title',
    Enter_promocode: 'Enter promocode',
    Enter_partner: 'Enter partner',
    Partner: 'Partner',
    Usage_Time: 'Usage Time',
    Enter_usage_time: "Enter usage time",
    Please_Select_Type: 'Please Select Type',
    Type: 'Type',
    Enter_Expiry_Date: 'Enter Expiry Date',
    Enter_first_title: 'Enter first title',
    Website_Url: 'Website Url',
    Enter_website_url: 'Enter website url',
    Select: "Select",
    Latest_Added: "Latest Added",
    ACHIEVEMENT_DETAILS: 'ACHIEVEMENT DETAILS',
    Enter_level_name: 'Enter level name',
    Enter_minimum_trips: 'Enter minimum trips',
    Enter_amount: 'Enter amount',
    Amount: 'Amount',
    Field_is_required: "Field is required",
    Add_Customer: "Add Customer",
    Pages_Customer: "Pages / Customer",

    APPROVAL_DETAILS: 'APPROVAL_DETAILS',
    Enter_name: 'Enter name',
    Enter_Phone_Number: 'Enter Phone Number',

    Vehicle_Registration_No: 'Vehicle Registration No.',
    Enter_Vehicle_Registration_No: 'Enter Vehicle Registration No.',
    Enter_Driver_License: 'Enter Driver License',
    Driver_License: "Driver License",
    Please_Select_Vehicle_Insurance: 'Please Select Vehicle Insurance',
    Please_Select_Model: "Please Select Model",
    Please_Select_Status: "Please Select Status",
    Enter_Email: 'Enter Email',
    Remove: "Remove",
    User_Family: "User Family",
    Profile_Information: "Profile Information",
    Notification_Setting: "Notification Setting",
    No_user_family_data_available: "No user family data available.",
    ACCOUNT: "ACCOUNT",
    Location: "Location",
    Mobile_No: "Mobile No",
    Email_me_when_ride_completed: "Email me when ride completed",
    Email_me_when_ride_cancel: "Email me when ride cancel",
    Email_me_when_someone_follow_me: "Email me when someone follow me",
    Platform_Setting: "Platform Setting",
    Hi_Im: "Hi, I’m",
    Decisions_paragraph: "Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).",

    Dashboard: 'Dashboard',
    Total_Driver: 'Total Driver',
    Total_User: 'Total User',
    Drivers: 'Drivers',
    Active_Drivers: 'Active Drivers',
    Approved_Drivers: 'Approved Drivers',
    Unapproved_Drivers: 'Unapproved Drivers',
    Inactive_users: 'Inactive users',
    Active_Users: 'Active Users',
    Total_Users: 'Total_Users',
    Driver_Statistics: 'Driver Statistics',
    User_Statistics: 'User Statistics',
    Add_Promocode: 'Add Promocode',

    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    Fixed: "Fixed",
    Percent: "Percent",
    Multiple: "Multiple",
    Single: "Single",
    Value: "Value",
    Delete_The_Logo: "Are You Sure, You Want To Delete The Logo?",
    Website_App_Url: "Website / App Url",
    PROMOCODE_DETAILS: 'PROMOCODE DETAILS',
    EXPIRED_PROMOCODE: 'EXPIRED PROMOCODE',
    ACTIVE_PROMOCODE: 'ACTIVE PROMOCODE',
    TOTAL_PROMOCODE: 'TOTAL PROMOCODE',
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: 'May',
    June: 'June',
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",

  },
}