import  { useEffect } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button } from "react-bootstrap";
import { InputCtrl, SelectCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useToast } from "../../hooks";
import FileUpload from "../forms/FileUpload";
import { deleteFile, getFile } from "../../helpers/api/file";
import Loader from "../../components/Loader";
import { deleteAchievement, getAchievement, saveAchievement } from "../../helpers/api/achievement";
import { useTranslation } from "react-i18next";

interface achievementTypes {
  id: number;
  minimumTrips: number | string;
  name: string;
  amount: number | string;
  isEnabled: boolean
  statusId: number | string;
  status:string | number;
  fileId:string;
  
}

const defaultValues: achievementTypes = {
  id: 0,
  name: "",
  minimumTrips:"",
  amount: "",
  isEnabled: true,
  statusId: '',
  status:"",
  fileId:''
};

const AchievementDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { showToast, dissmisToast } = useToast();
  const [uploadedFile, setUploadedFile]: any = useState({});
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrl, setImageUrl]: any = useState('');
  const [imageId, setImageId]: any = useState();
  const { t } = useTranslation();


  const [loading, setLoadoing]: any = useState(false);

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<achievementTypes>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<achievementTypes> = async (data) => {

    try {
   
      const response = await saveAchievement({
        id: data.id,
        name: data.name,
        minimumTrips:data.minimumTrips,
        amount: data.amount,
        isEnabled: true,
        fileId: imageId?imageId:data.fileId,
        status:data.statusId
       
      });
      navigate(-1);

      if (response.statusCode === 200) {
        showToast("success", response.message);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const GetDetails = async () => {
    setLoadoing(true)
    try {
      const response = await getAchievement({ id });
      // Use reset method to update the form values
      reset({
        id: response.data.id,
        name: response.data.name,
        minimumTrips:response.data.minimumTrips,
        amount: response.data.amount,
        isEnabled: true,
        fileId:response.data.fileId,
        statusId:response.data.status
      });
      
      
      
      if (response?.data?.fileId) {
        const logoId:number | string= response?.data?.fileId        
        const imageResponse = await getFile({ id: logoId })

        if (imageResponse && imageResponse?.data && imageResponse?.data?.id !== 0) {
          setImageUrl(imageResponse?.data?.fullFileUrl)
          setIsUploaded(true)
          setUploadedFile(imageResponse?.data)
          
        }
        else {
          setImageUrl("")
          setIsUploaded(false)
          setUploadedFile({})
        }

      }
      setLoadoing(false)
      // showToast("success", response.message);
    } catch (error) {
      console.log(error, "error");
      setLoadoing(false)
    }
  };

  async function deleteData() {
    let response = await deleteAchievement(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }



  const statusData: any[] = [
    {
      label: "ACTIVE",
      value: "ACTIVE"
    },
    {
      label: "INACTIVE",
      value: "INACTIVE"
    }
  ]

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);

  const breadCrumbItems = [
    { label: "Achievements", path: "/achievements" },
    { label: `${(Number(params.id)) ? 'Edit' : 'Add'} Achievement`, path: path },
  ]

  const handleCallBack = (data: any) => {
    setUploadedFile(data)
    setIsUploaded(true)
    setImageUrl(data?.fullFileUrl)
  }

  const deleteImage = async () => {
    const imageId = uploadedFile?.id;
    const res = await deleteFile(imageId)
    setIsUploaded(false);
    setImageUrl('');
    setUploadedFile({})
  }

  return (
    <>
      <PageTitleBox name="TRIAD School District" pageTitle={t('ACHIEVEMENT_DETAILS')}  />
      {loading && <Loader />}
    

      <form name="chat-form" id="chat-form" className=" w-75 p-5 pt-2 m-auto roboto-thin" onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body>
            <Row>
              <Col xl={12}>
                <Row>
                <Col xl={12}>
                <div>
                </div>
                {isUploaded ? <div className="d-flex justify-content-center "><img className="uploaded-image" src={imageUrl} />

                  <div className="remove-button">
                    <DeleteConfirm
                      isHide={!isUploaded}
                      disabled={apiStatus.inprogress}
                      confirm={() => {
                        deleteImage();
                      }}
                      message={t('Delete_The_Logo')}
                    />
                  </div>


                </div> : <FileUpload handleCallBack={handleCallBack} setImageId={setImageId} />}

              </Col>
                  <Col xl={12} className="mt-2">
                    <InputCtrl
                      control={control}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t('Enter_level_name')}
                      label={t('Level')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      componentName="FullName"
                    />
                    
                  </Col>
                  <Col xl={12}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="minimumTrips"
                      id="minimumTrips"
                      placeholder={t('Enter_minimum_trips')}
                      label={t('Minimum_Trips')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                     componentName="Number"
                    />
                  </Col>
                  <Col xl={12}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder={t('Enter_amount')}
                      label={t('Reward_Amount')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                     componentName="Number"
                    />
                  </Col>
                  <Col xl={12}>
                    <SelectCtrl
                      control={control}
                      name="statusId"
                      id="statusId"
                      placeholder={t('Please_Select_Status')}
                      label={t('Status')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={statusData.map((item)=>({label: t(item.label), value: item.value}))}
                      showError={showError}
                    />
                  </Col>
                </Row>
              </Col>
           
            </Row>
            <div className="d-flex justify-content-center align-items-baseline">
            <div className="button-list  w-100 d-flex justify-content-center  align-items-baseline">
     <Button
       variant="primary"
       type="submit"
       disabled={apiStatus.inprogress}
       className="px-4 rounded w-50 button-54">
       {t('Save')}
     </Button>
     </div>
 
   
     <DeleteConfirm
       isHide={!Number(params.id)}
       disabled={apiStatus.inprogress}
       confirm={() => {
         deleteData();
       }}
     />

 </div>
          </Card.Body>
        </Card>
       
      </form>
    </>
  );
};

export default AchievementDetails;
