import { APICore } from "./apiCore";

const api = new APICore();

function savePartner(params: any) {
    const baseUrl = "/api/Partner/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllPartners(params: {
    pageNumber: number;
    pageSize: number;
    //  query: string;

}) {
    const baseUrl = "/api/Partner/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getPartner(params: { id: number | string }) {
    const baseUrl = "/api/Partner/Get";
    return api.get(`${baseUrl}`, params);
}

const deletePartner = (id: any) => {
    const baseUrl = "/api/Partner/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { savePartner, getPartner, getAllPartners, deletePartner }