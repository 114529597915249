import { APICore } from "./apiCore";

const api = new APICore();
function getAllTrips(params: {
    pageNumber: number;
    pageSize: number;
    userId?: number;
    filters?: string;
    startTime?:number;
    endTime?:number;
    status?:string;
    isRecentTrip?:boolean

}) {
    const baseUrl = "/api/Trip/GetAll";
    return api.get(`${baseUrl}`, params);
}
const GetCountrys = (params: any) => {
    const baseUrl = "/api/Places/GetCountries";
    return api.get(`${baseUrl}`, params);
  };
  const GetStates = (params: any) => {
    const baseUrl = `/api/Places/GetStates`;
    return api.get(`${baseUrl}`, params);
  };
  const GetCitys = (params: any) => {
    const baseUrl = `/api/Places/GetCities`;
    return api.get(`${baseUrl}`, params);
  };
export { getAllTrips,GetCountrys,GetStates,GetCitys}