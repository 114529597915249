import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import { getAllUser } from "../../helpers";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import Search from "../../components/Search";
import { useTranslation } from "react-i18next";
import SelectFilter from "../../components/SelectFilter";
import { useState } from "react";
const RidePricing = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [mystatus, setMyStatus] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      Header: t("Sr. No"),
      accessor: "id",
    },
    {
      Header: t("Type of Vehicle "),
      accessor: () => <span>Joudma SUV</span>,
    },
    {
      Header: t("Ratings"),
      accessor: () => <span>4.9</span>,
    },
    {
      Header: t("Distance"),
      accessor: () => <span>4 Km</span>,
    },
    {
      Header: t("Earnings"),
      accessor: () => <span>$5670</span>,
    },
    {
      Header: t(" "),
      accessor: () => <div className="opacity-50">Edit</div>,
    },
  ];
  const {
    list: usersList,
    isFetching,
    pagination,
    reCall: refetchData,
  } = useApi(
    "GetAllUsers",
    (data: any) =>
      getAllUser({
        pageNumber: 1,
        pageSize: 10,
        filters: mystatus ? mystatus : "",

        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  const StatusOptions: any[] = [
    {
      label: "ADMIN",
      value: "ADMIN",
    },
    {
      label: "USER",
      value: "USER",
    },
    {
      label: "DRIVER",
      value: "DRIVER",
    },
    {
      label: "ALL(56)",
      value: "",
    },
  ];

  const handleStatusChange = (selectedStatus: {
    label: string;
    value: string;
  }) => {
    setMyStatus(selectedStatus.value);
    refetchData({
      filters: selectedStatus.value,
    });
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <PageTitleBox
              leftItem={
                <div className="d-flex flex-wrap">
                  <SelectFilter
                    pagination={pagination}
                    options={StatusOptions}
                    paramName=""
                    onChange={handleStatusChange}
                  />

                  <Button
                    variant="primary"
                    className="btn ms-1 rounded-1 "
                    onClick={() => navigate("./add")}
                  >
                    {t("ADD NEW")} <i className="uil uil-plus"></i>
                  </Button>
                </div>
              }
            />{" "}
            <div className="w-75">
              {" "}
              <Search pagination={pagination} width={"w-100"} />
            </div>
          </div>

          <Table
            isFetching={isFetching}
            columns={columns}
            data={usersList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            isSelectable={true}
            {...pagination}
            onRowClick={(row) => {
              navigate("" + row.original.id);
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default RidePricing;
