import React, { useEffect, useRef } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button, } from "react-bootstrap";
import { InputCtrl, SelectCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useApi, useToast } from "../../hooks";
import { deleteAward, getAward, saveAward } from "../../helpers/api/award";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { deleteCoupon, getAllCoupon, getCoupon, saveCoupon } from "../../helpers/api/promocode";
import moment from "moment";
import { FormatDate } from "../../common/utility";

import Table from "../../components/Table";



//Default values
const defaultValues: {
  id: number;
} = {
  id: 0,
 
};

const PricingDetails = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const { showToast} = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    id: number;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    id: number;
   
  }> = async (data) => {
   
    try {
      const response = await saveCoupon({
        id: data.id,
      });
      navigate(-1);

      if (response.statusCode === 200) {
        showToast("success", response.message || "success");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true)
    try {
      const response = await getCoupon({ id });
   
      reset({
        id: response.data.id,
      });
      setLoadoing(false)
    
    } catch (error) {
      setLoadoing(false)
      console.log(error, "error");
    }
  };

  async function deleteData() {
    let response = await deleteCoupon(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);


  const typeData: any[] = [
    {
      label: "Fixed",
      value: 'fixed'
    },
    {
      label: "Percent",
      value: 'percent'
    },
    {
      label: "Value",
      value: 'value'
    }
  ]

  return (
    <>
      <h3 className="fw-bold mt-3 mb-2">ADD Pricing</h3>
      <form name="chat-form" id="chat-form" onSubmit={handleSubmit(onSubmit)}>
        <Card >
          <Card.Body >
            {loading && <Loader />}
            <Row className="p-2">
              <Col xl={12}>
                <Row className="roboto-thin ">
                  <Col xl={6}>
                  <SelectCtrl
                      control={control}
                      name="vehicletype "
                      id="vehicletype"
                      placeholder={t('Select')}
                      label={t('Type of Vehicle ')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={typeData}
                      showError={showError}
                    />
                  </Col>
                  <Col xl={6}>
                  <SelectCtrl
                      control={control}
                      name="rating"
                      id="rating"
                      placeholder={t('Select')}
                      label={t('Ratings')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={typeData}
                      showError={showError}
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="distance"
                      id="distance"
                      placeholder={t('5xxxxxxxxx')}
                      label={t('Distance')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    // componentName="Number"
                    />
                  </Col>

                  <Col xl={6}>

                    <SelectCtrl
                      control={control}
                      name="earn"
                      id="earn"
                      placeholder={t('Select')}
                      label={t('Earnings')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={typeData}
                      showError={showError}
                    />

                  </Col>

                
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-baseline ">
              <div className="button-list w-75 d-flex justify-content-between  align-items-baseline">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={apiStatus.inprogress}
                  className="px-4 w-75 rounded ">
                  {t('Approve')}
                </Button>
                <Button
              variant="outline-secondary"
              className="px-4  w-75 rounded ms-3"
              onClick={() => {
                navigate(-1);
              }}>
              {t('Cancel')}
            </Button>

              </div>
              {/* <div>
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div> */}
            </div>
          </Card.Body>
        </Card>

      </form>

    

    </>
  );
};

export default PricingDetails;

