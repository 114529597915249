import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getdashboard } from "../../../helpers/api/dashboard";
import ActiveUserGraph from "./ActiveUserGraph";
import SalesOverviewChart from "./SalesOverviewChart";
import ActiveDriver from "./ActiveDriver";

const JoudmaDashboard = () => {
  const [dateRange, setDateRange] = useState<any>([
    new Date(),
    new Date().setDate(new Date().getDate() + 7),
  ]);
  const [driverStats, setDriverStats] = useState<any>(null);

  const [startDate, endDate] = dateRange;
  const { t } = useTranslation();
  const onDateChange = (date: Date) => {
    if (date) {
      setDateRange(date);
    }
  };

  const GetUserDashboard = async () => {
    try {
      const response = await getdashboard({type:"DASHBOARD",filters:null});

      if (response.statusCode === 200) {
        setDriverStats(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetUserDashboard();
  }, []);
  const cardData = [
    {
      title: t("Todays_Money"),
      value: driverStats?.todaysMoney,
      icon: "./Wallet.svg",
    },
    {
      title: t("Todays_Users"),
      value: driverStats?.todaysUsers,
      icon: "./user.svg",
    },
    {
      title: t("Active_Drivers"),
      value: driverStats?.activeDrivers,
      icon: "./users2.svg",
    },
    {
      title: t("Today_Trips"),
      value: driverStats?.todaysTrips,
      icon: "./pin.svg",
    },
  ];
  return (
    <>
      <Row className="row-cols-md-4 row-cols-sm-2 row-cols-1 mt-3 gx-3 me-3">
        {cardData.map((item: any, index: number) => (
          <Col key={index}>
            <Card className="customcard">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="theme-color mb-1 fw-bold">{item?.title}</h5>
                    <h5 className="fw-bold mb-0">{item?.value}</h5>
                  </div>
                  <div>
                    <div className="icon-background">
                      <img src={item?.icon} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row className="row-cols-md-2 row-cols-sm-2 row-cols-1 me-3  g-3">
        <Col>
          <ActiveUserGraph data={driverStats} />
        </Col>
        <Col>
          <Card className="card-height">
            <Card.Body>
              <div className="ms-3">
                <h2 className="theme-color fw-bold">{t("Sales_Overview")}</h2>
                <h4 className="theme-color ">
                  (+5)
                  <span className="text-secondary fw-normal ms-1">{t("more_in_2024")}</span>
                </h4>
              </div>

              <SalesOverviewChart filter={"YEAR"} />
              {/* <SalesOverviewChart/> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="row-cols-md-2 row-cols-sm-2 row-cols-1 me-3  g-3">
        <Col>
          <Card className="card-height h-100">
            <Card.Body>
              <div>
                <h1 className="fw-bold">{t("Live_MAP")}</h1>
                <h4 className="text-secondary">{t("See_all_users_and_trips")}</h4>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <img
                  src="./map.jpg"
                  alt="map"
                  className="img-fluid"
                  width="100%"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="position-relative">
          <ActiveDriver />
        </Col>
      </Row>
    </>
  );
};

export default JoudmaDashboard;
