import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";

// components
import FileUploader from "../../components/FileUploader";
import { SubmitHandler } from "react-hook-form";
import { saveFile } from "../../helpers/api/file";
import { useToast } from "../../hooks";
import { useTranslation } from "react-i18next";

const FileUpload = (props:any) => {

  const {handleCallBack} = props;

  const [fileData, setFileData]: any = useState([])
  const { showToast} = useToast();
  const { t } = useTranslation();

  const  onSubmit: SubmitHandler<any> = async () => {

    if(fileData && fileData.length>0){

    const fileUploaded: any = fileData[0];

    if (fileUploaded) {
      const allowedTypes = [
        '.jpg',
        '.jpeg',
        '.png',
      ];
      const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB

      if (
        true || allowedTypes.includes(
          fileUploaded.name.slice(fileUploaded.name.lastIndexOf('.'))
        )
      ) {
        if (fileUploaded.size !== 0 && fileUploaded.size <= maxSizeInBytes) {
          // const reader = new FileReader();
          // let base64String = '';

          // reader.onload = function (even: any) {
          //   /* eslint-disable */
          //   base64String = even.target.result.split(',')[1]; // Extract base64 content after the comma
          // };
          // reader.readAsDataURL(fileUploaded);

          // console.log({base64String})

          const finalPayload = {
            "File": fileUploaded,
            "Type": fileUploaded.type
          }

          try {
            const response: any = await saveFile(finalPayload);
            if(handleCallBack && response){
              handleCallBack(response?.data?.data)
            }
            if (response.status === 200) {
              props.setImageId(response.data.data.id)
              showToast("success", response.message);
            } else {
              console.log(response.error);
              // showToast("error", response.message);
            }
          } catch (error) {
            console.log(error);
          }

        } else {
          let desc: string = 'File size exceeds the limit of 1 MB.'
          if (fileUploaded.size === 0) {
            desc = 'No content, please upload a valid file.'
          }
          // show toast message
          showToast("error", desc);
        }
      } else {
        // show message -- 

        showToast("error", "Invalid File Type.");
      }
    }
  }
  else{
    showToast('error', 'Please upload a file.')
  }
    // saveFile

  };
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          {/* <Card>
            <Card.Body> */}
              {/* <h4 className="header-title theme-color mt-0 mb-1">{t("Upload_File")}</h4> */}
              {/* <p className="sub-header">
                DropzoneJS is an open source library that provides drag’n’drop
                file uploads with image previews.
              </p> */}

              <FileUploader
              
                onFileUpload={(files) => {
                  if(files && files.length>0){
                    setFileData(files)
                  }
                  else{
                    showToast('error', 'Please upload a file.')
                  }
              

                }}

                
              />

              <div className="clearfix text-end mt-1">
                <Button onClick={onSubmit} variant="outline-primary">
                  <i className="uil uil-upload-alt "></i>
                </Button>
              </div>
      
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FileUpload;
