import { APICore } from "./apiCore";

const api = new APICore();

function saveAchievement(params: any) {
    const baseUrl = "/api/Achievement/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllAchivements(params: {
    pageNumber: number;
    pageSize: number;
    //  query: string;

}) {
    const baseUrl = "/api/Achievement/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getAchievement(params: { id: number | string }) {
    const baseUrl = "/api/Achievement/Get";
    return api.get(`${baseUrl}`, params);
}

const deleteAchievement = (id: any) => {
    const baseUrl = "/api/Achievement/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveAchievement, getAchievement, getAllAchivements, deleteAchievement }