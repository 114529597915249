import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import { getdashboard } from "../../../helpers/api/dashboard";

const ActiveUserGraph = (props: any) => {
  const [graphData, setGraphData] = useState<any>();
  const [isfetching,setIsFetching]=useState<boolean>(true);
  const getGraph = async () => {
    try {
      const response = await getdashboard({
        type: "ACTIVE_USERS_GRAPH",
        filters: "YEAR",
      });
      if (response?.statusCode === 200) {
        setGraphData(response.data);
        setIsFetching(false)
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const { t } = useTranslation();
  const series = [
    {
      name: "Series 1",
      data: graphData?.map((item: any) => item.activeUsers),
    },
  ];

  const options: any = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: ["", "", "", "", "", "", "", "", "", "", "", ""],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      max: 500,
      labels: {
        style: {
          colors: ["#FFFFFF"],
        },
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FFFFFF"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
  };
  useEffect(() => {
    getGraph();
  }, []);
  return (
    <>
    <Card className="card-height">
        <Card.Body>
         <div className="status-color rounded-2">
         {isfetching?<Loader/>:
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={320}
            />}
          </div>

          <div>
            <h3 className="fw-bold">{t("Active_Users")}</h3>
            <p className="text-success">
              (+23)<span className="text-secondary">{t("than_last_week")}</span>
            </p>

            <Row className=" row-cols-md-4 row-cols-sm-2 row-cols-1 g-3 px-2">
              <Col>
                <div className="d-flex">
                  <div className="status-color p-1 rounded-2">
                    <img src="./Wallet.svg" className="img-fluid" alt="" />
                  </div>
                  <h5 className="fw-bold theme-color ms-1">{t("Users")}</h5>
                </div>
                <h6 className="fw-bold">{props?.data?.totalUsers}</h6>
                <div className="progress-show">
                  <ProgressBar
                    variant="primary"
                    now={75}
                    style={{ height: 5 }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      
    </>
  );
};

export default ActiveUserGraph;
