import React, { FC } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Loader from "../../components/Loader";
import GoogleMaps from "../maps/GoogleMaps";


interface IProps {
  data: any[],
  isFetching: Boolean
}

/**
* @author
* @function @FleetMap
**/

export const FleetMap: FC<IProps> = (props) => {
  const { t } = useTranslation();
  if (props.isFetching) {
    return <Loader />; 
  }
  return (
    <div className="m-0 p-0">
      {/* <Row className="justify-content-end">
        <Col className="d-flex my-2 justify-content-end" xs={12} md={12}>
          <Button
            variant="outline-primary"
            className="btn ms-1"
          >
            {t('Map')}
          </Button>
          <Button
            variant="outline-primary"
            className="btn ms-1"
          >
            {t('Setllite')}
          </Button>
        </Col>
      </Row>
      <img style={{ maxWidth: '100%' }} src={'./FleetMapDark.png'} alt="fleetmap" /> */}
      <GoogleMaps data={props.data} />
    </div>
  )
}