import { useEffect} from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button } from "react-bootstrap";
import { InputCtrl, } from "../../controllers";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import { deleteFile, getFile } from "../../helpers/api/file";
import Loader from "../../components/Loader";
import { deleteAchievement} from "../../helpers/api/achievement";
import { useTranslation } from "react-i18next";

import { ApproveOrReject, getUserGetDriverWithDetails } from "../../helpers/api/user";

interface driverApprovalTypes {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  vehicleType: string;
  status: string;
  isEnabled: boolean
  statusId: number | string;
  registrationNumber: string;
  licensePlate: string;
  model: string;
  insuranceNumber: string;
  vehicleTypeName: string
  make:string
}



//Default values
const defaultValues: driverApprovalTypes = {
  id: 0,
  name: "",
  isEnabled: true,
  statusId: '',
  email: "",
  phoneNumber: "",
  status: "",
  vehicleType: '',
  registrationNumber: '',
  licensePlate: '',
  model: '',
  insuranceNumber: '',
  vehicleTypeName: '',
  make:" "
};

const DriverApprovalDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  // const [roles, setRoles] = useState<any[]>([]);
  const { showToast, dissmisToast } = useToast();
  const [details,setDetails]=useState<any>();
  const [uploadedFile, setUploadedFile]: any = useState({});
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrl, setImageUrl]: any = useState('');
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();



  
  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<driverApprovalTypes>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };
 
  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit = async (action: string) => {
   
    if (apiStatus.inprogress) return;

   apiStatus.inprogress= true 
    try {
      const response = await ApproveOrReject({
        id: id,
        approvedBy: 1,
        action: action
      });

      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }finally {
 apiStatus.inprogress=false
    }
  };

  const GetDetails = async () => {
    setLoadoing(true)
    try {
      const response = await getUserGetDriverWithDetails({ id });

      reset({
        ...response.data,
        status:response?.data.status==="ONLINE"?"ACTIVE":"INACTIVE",
        ...response.data.vehicle?.items[0],
    
      });
      if (response.statusCode===200) {
        setDetails(response?.data)
 
        if (response?.data?.logoFileId){ const logoId: any = response?.data?.logoFileId
          const imageResponse = await getFile({ id: logoId })
  
          if (imageResponse && imageResponse?.data && imageResponse?.data?.id !== 0) {
            setImageUrl(imageResponse?.data?.fullFileUrl)
            setIsUploaded(true)
            setUploadedFile(imageResponse?.data)
          }
          else {
            setImageUrl("")
            setIsUploaded(false)
            setUploadedFile({})
          }
  }
       
      }
      setLoadoing(false)
      // showToast("success", response.message);
    } catch (error) {
      console.log(error, "error");
      setLoadoing(false)
    }
  };

  async function deleteData() {
     
    if (apiStatus.inprogress) return;

   apiStatus.inprogress= true 
    let response = await deleteAchievement(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate("/partners");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }finally {
      apiStatus.inprogress=false
         }
  }




  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);

  const breadCrumbItems = [
    { label: "approvals", path: "/approvals" },
    { label: `${(Number(params.id)) ? 'Edit' : 'Add'} approvals`, path: path },
  ]

  const handleCallBack = (data: any) => {
    setUploadedFile(data)
    setIsUploaded(true)
    setImageUrl(data?.fullFileUrl)

  }

  const deleteImage = async () => {
    const imageId = uploadedFile?.id;
    const res = await deleteFile(imageId)
    setIsUploaded(false);
    setImageUrl('');
    setUploadedFile({})
  }

  return (
    <>
      <PageTitleBox name="TRIAD School District" pageTitle={t('APPROVAL_DETAILS')} />
      {loading && <Loader />}
      <form name="chat-form" className="roboto-thin" id="chat-form" >
        <Card>
          <Card.Body>
            <Row>
              <Col xl={12}>
                <Row>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder={t('username')}
                      label={t('Name')}
                      showError={showError}
                      disabled={true}
                      className="mb-3 inputChange"
                      required={false}
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder={t('Phone_Number')}
                      label={t('Phone_Number')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t('usernamemail')}
                      label={t('Email')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                    />
                  </Col>
                  <Col xl={6}>
                  <InputCtrl
                      control={control}
                      type="vehicleType"
                      name="vehicleType"
                      id="vehicleType"
                      placeholder={t('Vehicle_Type')}
                      label={t('Vehicle_Type')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                    />

                  </Col>

                  <Col xl={6}>

                  <InputCtrl
                      control={control}
                      type="licensePlate"
                      name="licensePlate"
                      id="licensePlate"
                      placeholder={t('Driver_License')}
                      label={t('Driver_License')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                    />
                  

                  </Col>
                  <Col xl={6}>
                  <InputCtrl
                      control={control}
                      type="insuranceNumber"
                      name="insuranceNumber"
                      id="insuranceNumber"
                      placeholder={t('Vehicle_Insurance')}
                      label={t('Vehicle_Insurance')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                    />
                  </Col>
                  
                  <Col xl={6}>
                 
                  <InputCtrl
                      control={control}
                      type="text"
                      name="status"
                      id="statusid"
                      placeholder={t('Status') } 
                      label={t('Status')}
                      showError={showError}
                      required={false}
                      disabled={true}
                      className="mb-3  inputChange"
                     
                    />
                  </Col>

                  <Col xl={6}>
                  <InputCtrl
                      control={control}
                      type="text"
                      name="make"
                      id="make"
                      placeholder={t('Car_make')}
                      label={t('Car_Make')}
                      showError={showError}
                      disabled={true}
                      className="mb-3 inputChange"
                      required={false}
                    />
                  </Col>
                  <Col xl={6}>
                  <InputCtrl
                      control={control}
                      type="text"
                      name="model"
                      id="model"
                      placeholder={t('Car_Model')}
                      label={t('Car_Model')}
                      showError={showError}
                      disabled={true}
                      className="mb-3 inputChange"
                      required={false}
                    />                  
                  </Col>
                </Row>
              </Col>

              <Col xl={12}>
                <div className="img-title pb-2 " >{t("Images")}</div>
                <Row className="justify-content-center  bg-light p-3 g-2 dash-border">
                  {/* {
                     details?.vehicle?.items?.length > 0 ?details?.vehicle?.items?.map((item:any)=>{
                      return (<Col md={3} key={item?.id}>
                    <div className="img-here">
                      <img src={item?.fileFullUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                  
                 
                
                )
                    }) :  */}
                    {
                       details?.licenseFullFileUrl ||  details?.insuranceFullFileUrl ||  details?.identificationFullFileUrl ? (<>

                       {
                        details?.licenseFullFileUrl && <Col md={3}>
                    <div className="img-here">
                      <img src={details?.licenseFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                       }
                       
                 {
                  details?.insuranceFullFileUrl &&
                   <Col md={3}>
                    <div className="img-here">
                      <img src={details?.insuranceFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                 }
                  {
                    details?.identificationFullFileUrl &&  <Col md={3}>
                    <div className="img-here">
                      <img src={details?.identificationFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                  }
                </>):  
                    <Col md={3} >
                    <div className="">
                     <p className="text-center">No images found.</p>
                    </div>
                  </Col>

                    }
                     
                
                  {/* <Col md={3}>
                    <div className="img-here">
                      <img src={details?.licenseFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="img-here">
                      <img src={details?.insuranceFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="img-here">
                      <img src={details?.identificationFullFileUrl} className="img-fluid custom-image" alt="" />
                    </div>
                  </Col> */}
                </Row>



              </Col>

            </Row>
            <div className="d-flex justify-content-between align-items-baseline">

              <div className="button-list m-auto my-3  w-75 d-flex justify-content-center  align-items-baseline">
                {
                  details?.approvalStatus === "APPROVED" ?  <Button
                  variant="primary"
                  type="button"
                  
                  disabled={true}
                  className="px-4 button-54 rounded w-50">
                  {t('Approved')}

                </Button> : 
                
                 details?.approvalStatus === "REJECTED" ?  <Button
                  variant="danger"
                  disabled={true}
                  className="px-4 button-54    rounded w-50"

                >
                  {t('Rejected')}
                </Button> :
                
                <>
                 <Button
                  variant="primary"
                  type="button"
                  onClick={() => onSubmit("APPROVE")}
                  disabled={apiStatus.inprogress}
                  className="px-4 button-54 rounded w-50">
                  {t('Approve')}

                </Button>
                <Button
                  variant="danger"
                  disabled={apiStatus.inprogress}
                  className="px-4 button-54    rounded w-50"
                  onClick={() => onSubmit("REJECT")}

                >
                  {t('Reject')}
                </Button>
                </>
                }
               
              </div>
              {/* <div>
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div> */}
            </div>
          </Card.Body>
        </Card>

      </form>
    </>
  );
};

export default DriverApprovalDetails;
