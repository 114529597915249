import { APICore } from "./apiCore";

const api = new APICore();

function saveVehicleType(params: any) {
    const baseUrl = "/api/VehicleType/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllVehicleType(params: {
    pageNumber: number;
    pageSize: number;
    //  query: string;

}) {
    const baseUrl = "/api/VehicleType/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getVehicleType(params: { id: number | string }) {
    const baseUrl = "/api/VehicleType/Get";
    return api.get(`${baseUrl}`, params);
}

const deleteVehicleType = (id: any) => {
    const baseUrl = "/api/VehicleType/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveVehicleType, getVehicleType, getAllVehicleType, deleteVehicleType }