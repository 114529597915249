import { Card } from "react-bootstrap";
import { ApexOptions } from "apexcharts";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface AwardStasProps {
  title?: string;
  stats?: string;
  trend?: {
    textClass: string;
    icon: string;
    value: string;
  };
  colors?: Array<string>;
  icon?:string;
  backgroundcol?:string;
}

const AwardStasProps = ({
  title,
  stats,
  trend,
  colors,
  icon,
  backgroundcol
}: AwardStasProps) => {
  const { t } = useTranslation();

  //  default options
  const options: ApexOptions = {
    chart: {
      type: "area",
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    colors: colors,
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: (seriesName) => {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
  };

  // chart data
  const series = [
    {
      data: [25, 66, 41, 85, 63, 25, 44, 12, 36, 9, 54],
    },
  ];

  return (
    <Card className={`${backgroundcol} cursor-pointer`}>
      <Card.Body>
        <div className="d-flex">
          <div className="flex-grow-1">
            <h6 className={`text-uppercase fw-bold ${backgroundcol?"text-light":"theme-color"}`}>
              {t(`${title}`)}
            </h6>
            <h6 className={` fw-bold ${backgroundcol?"text-light":""}`}>{stats}</h6>
          </div>
          {icon && <div className="align-self-center flex-shrink-0 award-card-icon" >
            <span className={classNames("fw-bold", "fs-13")}>
              <img src={`./${icon}`}/>
             <div>
            
             </div>
            </span>
          </div>}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AwardStasProps;
