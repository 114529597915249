import { APICore } from "./apiCore";

const api = new APICore();

function saveRide(params: any) {
    const baseUrl = "/api/Trip/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllRides(params:{
    pageNumber:number;
    pageSize:number;
    startTime:string,
    endTime:string,
}) {
    const baseUrl = "/api/Trip/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getRide(params: { id: number | string }) {
    const baseUrl = "/api/Trip/Get";
    return api.get(`${baseUrl}`, params);
}

const deleteRide = (id: any) => {
    const baseUrl = "/api/Trip/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }
  function getTripStats(params: any) {
    const baseUrl = "/api/Trip/GetTripStats";
    return api.get(`${baseUrl}`,{});
}


export { saveRide, getAllRides, getRide, deleteRide,getTripStats }