import { Button, Card, Col, Row } from "react-bootstrap";
import {useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import { FormatDate } from "../../common/utility";
import { useTranslation } from "react-i18next";
import { getAllCoupon, getCardDetails } from "../../helpers/api/promocode";
import Search from "../../components/Search";
import { IoFilter } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";

const Promocode = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [cardData, setCardData] = useState<any>();
  const [search, setSearch] = useState<boolean>(false);
  const columns = [
    {
      Header: t("Sr_No"),
      accessor: "id",
    },
    {
      Header: t("Title"),
      accessor: "name",
    },
    {
      Header: t("Promocode"),
      accessor: "code",
    },
    {
      Header: t("Partners"),
      accessor: "partnerName",
    },

    {
      Header: t("Usage_Time"),
      accessor: "usageLimitAccess",
    },
    {
      Header: t("Type"),
      accessor: "type",
    },

    {
      Header: t("Expiry_Date"),
      accessor: (row: any) => {
        return FormatDate(row.validTillDate, "ll");
      },
    },

    {
      Header: t("Status"),
      accessor: (row: any) => {
        return (
          <div
            className={`text-light  px-1 rounded-3 text-center ${
              row.status ? "status-color" : "bg-secondary"
            }`}
          >
            {row.status ? "ACTIVE" : "INACTVE"}
          </div>
        );
      },
    },

    {
      Header: t(" "),
      accessor: () => <div className="opacity-50 float-end">Edit</div>,
    },
  ];

  const {
    list: partnerList,
    isFetching,
    pagination,
  } = useApi(
    "GetAllAwards",
    (data: any) =>
      getAllCoupon({
        pageNumber: 1,
        pageSize: 10,
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );
  const fetchCardsDetails = async () => {
    try {
      let response = await getCardDetails({type:"AWARDS",filters:null});
      if (response?.statusCode === 200) {
        setCardData(response?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchCardsDetails();
  }, []);
  const promoCard = [
    {
      title: t("TOTAL_AWARDS"),
      stats: cardData?.totalAwards,
      icon: "total-awards.svg",
    },

    {
      title: t("ACTIVE_AWARDS"),
      stats: cardData?.activeAwards,
      icon: "./CompletedRides.svg",
    },
    {
      title: t("EXPIRED_AWARDS"),
      stats: cardData?.expiredAwards,
      icon: "./CancelRide.svg",
    },
  ];
  return (
    <>
      <div>
        <Row className="row-cols-md-3 row-cols-sm-3  row-cols-1  mt-2  g-3">
          {promoCard.map((cardItem: any, index: number) => (
            <Col sm={6} xl={4}>
              <Card className="customcard" key={index}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="theme-color fw-bold mb-1">
                        {cardItem.title}
                      </h5>
                      <h5 className="fw-bolder mb-0">{cardItem.stats}</h5>
                    </div>
                    <div>
                      <div className="icon-background">
                        <img src={cardItem.icon} className="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Card>
        <Card.Body>
          <PageTitleBox
            leftItem={
              <div className="d-flex">
                <Button
                  variant="primary"
                  className="btn ms-1 rounded-1 "
                  onClick={() => navigate("./add")}
                >
                  {t("ADD_NEW")} <i className="uil uil-plus"></i>
                </Button>
              </div>
            }
            rightItem={
              <Row className="justify-content-around">
                <Col className="d-flex " xs={12} md={12}>
                  {search ? (
                    <Search pagination={pagination} />
                  ) : (
                    <FaSearch
                      size={18}
                      className="me-2 opacity-75"
                      onClick={() => setSearch(true)}
                    />
                  )}

                  <IoFilter className="fw-bold h-100 mx-2" size={20} />
                </Col>
              </Row>
            }
          />
          <Table
            isFetching={isFetching}
            columns={columns}
            data={partnerList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            isSelectable={true}
            {...pagination}
            onRowClick={(row) => {
              navigate("" + row.original.id);
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Promocode;
