import { Button, Card,Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import { getAllUser } from "../../helpers";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import Search from "../../components/Search";
import { useTranslation } from "react-i18next";
import SelectFilter from "../../components/SelectFilter";
import { useState } from "react";
import FeatherIcon from "feather-icons-react";

const Configuration = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [mystatus, setMyStatus] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      Header: t("ID"),
      accessor: "id",
    },
    {
      Header: t("User Name"),
      accessor: "fullName",
    },
    {
      Header: t("Phone Number"),
      accessor: (row: any) => {
        return row.phoneNumber || "NA";
      },
    },
    {
      Header: t("Email"),
      accessor: "email",
    },

    {
      Header: t("Role"),
      accessor: () => (
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="cursor-pointer text-secondary border-secondary"
          >
            {t("Select")}
            <i className="icon ms-4">
              <FeatherIcon icon="chevron-down" />
            </i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {StatusOptions.map((role, index) => (
              <Dropdown.Item className="notify-item" href="#" key={index}>
                <span>{role.label}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      Header: t(" "),
      accessor: () => <div className="opacity-50 float-end">Edit</div>,
    },
  ];

  const {
    list: usersList,
    isFetching,
    pagination,
    reCall: refetchData,
  } = useApi(
    "GetAllUsers",
    (data: any) =>
      getAllUser({
        pageNumber: 1,
        pageSize: 10,
        filters: mystatus ? mystatus : "",

        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  const StatusOptions: any[] = [
    {
      label: "ADMIN",
      value: "ADMIN",
    },
    {
      label: "USER",
      value: "USER",
    },
    {
      label: "DRIVER",
      value: "DRIVER",
    },
    {
      label: "ALL",
      value: "",
    },
  ];

  const handleStatusChange = (selectedStatus: {
    label: string;
    value: string;
  }) => {
    setMyStatus(selectedStatus.value);
    refetchData({
      filters: selectedStatus.value,
    });
  };
  return (
    <>
      <Card className="mt-4 me-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <PageTitleBox
              leftItem={
                <div className="d-flex flex-wrap">
                  <Button
                    variant="primary"
                    className="btn me-2 rounded-1 "
                    onClick={() => navigate("./add")}
                  >
                    {t("ADD_NEW")} <i className="uil uil-plus"></i>
                  </Button>
                  <SelectFilter
                    pagination={pagination}
                    options={StatusOptions}
                    paramName=""
                    onChange={handleStatusChange}
                  />
                </div>
              }
            />
            <div className="w-75">
              <Search pagination={pagination} width={"w-100"} />
            </div>
          </div>

          <Table
            isFetching={isFetching}
            columns={columns}
            data={usersList}
            sizePerPageList={[]}
            isSortable={true}
            isSelectable={true}
            pagination={true}
            {...pagination}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Configuration;
