import React from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";

interface SelectFilterProps {
  pagination?: any;
  options: any[];
  paramName: string;
  onChange: (selectedStatus?: any) => void; 
}

const SelectFilter = ({
  pagination,
  options = [],
  paramName = "status",
  onChange, 
}: SelectFilterProps) => {
  const {t}=useTranslation();
  const handleSelectChange = (
    selectedOption: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    if (pagination?.handleSelectFilter && selectedOption) {
      pagination?.handleSelectFilter(selectedOption.value || "", paramName);
    }
    onChange(selectedOption); 
  };

  return (
    <div>
      <Select
        placeholder={t("ALL")}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            border:"0px",
            outline:"none"

            
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#307e8a8c ' : 'white'&&  state.isSelected ? '#307E8A' : 'transparent',
            color: state.isSelected ? 'white' : '#000',
'&:active': {
      backgroundColor: '#307E8A',
    },
        
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#000',
            
          }),
        }}
        options={options}
        onChange={(selectedOption) => {
          handleSelectChange(selectedOption);
        }}
       
      />
    </div>
  );
};

export default SelectFilter;
