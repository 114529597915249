import { Card, Button } from "react-bootstrap";
import { useApi, useToast } from "../../hooks";
import { useEffect, useState } from "react";
import { FleetMap } from "./FleetMap";
import { useTranslation } from "react-i18next";
import { getAllTrips } from "../../helpers";
import Table from "../../components/Table";
import useInterval from "../../common/useInterval";
import Search from "../../components/Search";
import { GetCitys, GetCountrys, GetStates } from "../../helpers/api/trip";
import DropDownFilter from "../../components/DropDownFilter";

const FleetTracking = () => {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [countrys, setCountrys] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [citys, setCitys] = useState<any>([]);
  const [myQuery, setMyQuery] = useState("");
  const columns = [
    {
      Header: t("Ride_Id"),
      accessor: "id",
    },
    {
      Header: t("Driver_Name"),
      accessor: "driverName",
    },
    {
      Header: t("Customer_Name"),
      accessor: "customerName",
    },
    {
      Header: t("Phone_Number"),
      accessor: "driverMobileNo",
    },
    {
      Header: t("Type_of_Vehicle"),
      accessor: "vehicleType",
    },
    {
      Header: t("Vehicle_Number"),
      accessor: "vehicleNumber",
    },
    {
      Header: t("Current_Location"),
      accessor: "startAddress",
    },
    {
      Header: t("Drop_Location"),
      accessor: "endAddress",
    },
    {
      Header: t("  "),
      accessor: () => <span className="opacity-50"> track</span>,
    },
  ];
  const country = async () => {
    try {
      const response = await GetCountrys({
        pageNumber: 1,
        pageSize: 1000,
      });
      if (response && response.data?.items) setCountrys(response.data.items);
    } catch (error) {
      console.log(error);
    }
  };
  const state = async () => {
    try {
      const response = await GetStates({
        pageNumber: 1,
        pageSize: 1000,
        countryId: countryId,
      });

      if (response && response.data?.items) setStates(response.data?.items);
    } catch (error) {
      console.log(error);
    }
  };
  const citis = async () => {
    try {
      const response = await GetCitys({
        pageNumber: 1,
        pageSize: 1000,
        stateId: stateId,
      });
      if (response && response?.data?.items) setCitys(response.data?.items);
    } catch (error) {
      console.log(error);
    }
  };
  let [activeTab, setactiveTab] = useState<string>("Table");
  const [trips, setTrips] = useState<any[]>([]);

  const {
    list,
    isFetching,
    pagination,
    reCall: refetchData,
  } = useApi(
    "GetAllTrips",
    (data: any) =>
      getAllTrips({
        pageNumber: 1,
        pageSize: 10,
        userId: 0,
        query: myQuery ? myQuery : "",
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        setTrips(
          response && response.data && response.data.items
            ? response.data.items
            : []
        );
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        setTrips([]);
        showToast("error", error.message);
      },
    }
  );
  // useInterval(() => {
  //   getAllTrips({ pageNumber:2, pageSize: 10, userId: 0})
  //   .then((response) => {
  //     setTrips(response && response.data && response.data.items ? response.data.items : []);

  //     console.log(response);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //     setTrips([]);
  //     showToast("error", error.message);
  //   });
  // }, 5000);
  const [countryId, setCountryId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [cityId, setCityId] = useState<number>();
  const handleStatusChange = (selectedQuery: {
    label: string;
    value: string;
    countryid?: number;
    stateid?: number;
    cityid?: number;
  }) => {
    setMyQuery(selectedQuery.value);
    refetchData({
      query: selectedQuery.value,
    });
    if (selectedQuery?.countryid) {
      setCountryId(selectedQuery?.countryid);
    } else if (selectedQuery?.stateid) {
      setStateId(selectedQuery?.stateid);
    } else {
      setCityId(selectedQuery?.cityid);
    }
  };

  useEffect(() => {
    if (countryId) state();
    if (stateId) citis();
  }, [countryId, cityId, stateId]);
  useEffect(() => {
    country();
  }, []);
  return (
    <>
      <div className="d-flex mt-4 mb-2 step-select">
        <DropDownFilter
          pagination={pagination}
          options={countrys?.map((item: any) => {
            return {
              label: item.name,
              value: item.name,
              countryid: item.id,
            };
          })}
          paramName="query"
          onChange={handleStatusChange}
          placeholderName={t("Select_Country")}
        />

        <DropDownFilter
          pagination={pagination}
          options={states?.map((item: any) => {
            return {
              label: item.name,
              value: item.name,
              stateid: item.id,
            };
          })}
          paramName="query"
          onChange={handleStatusChange}
          placeholderName= {t("Select_State")}
          clasName="ms-3"
        />
        <DropDownFilter
          pagination={pagination}
          options={citys?.map((item: any) => {
            return {
              label: item.name,
              value: item.name,
              cityid: item.id,
            };
          })}
          paramName="query"
          onChange={handleStatusChange}
          placeholderName={t("Select_City")}
          clasName="ms-3"
        />
      </div>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap gap-1">
            <div className="   d-flex flex-wrap gap-1">
              <Button
                onClick={() => setactiveTab("Map")}
                className={`rounded-1 px-5  border-0
              ${activeTab === "Table" ? "active-color" : ""}`}
              >
                {t("Map")}
              </Button>
              <Button
                onClick={() => setactiveTab("Table")}
                className={`rounded-1 px-5  border-0 ${
                  activeTab === "Map" ? "active-color" : ""
                }`}
              >
                {t("Table")}
              </Button>
            </div>
            <Search pagination={pagination} width={"w-50"} />
          </div>
          {activeTab === "Table" ? (
            <Table
              isFetching={isFetching}
              columns={columns}
              data={trips}
              sizePerPageList={[]}
              isSortable={true}
              pagination={true}
              isSelectable={true}
              {...pagination}
            />
          ) : (
            <FleetMap data={trips} isFetching={isFetching} />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FleetTracking;
