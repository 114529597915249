import moment from "moment";

export const COOKIE_DOMAIN = ".taxi-apis.npit.pro";

export const FormatDate = (date: any, format: string = "ll") => {
    return moment(date).format(format);
  };

  export const RESET_PASSWORD_TOKEN_KEY = "taxi-reset-password-token-key";
  
  export const SetLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
    return true;
  };
  export const FormatCurrency = (amount: string | number = 0) => {
    let formattedValue = "$0";
    if (amount) formattedValue = `$${amount}`;
    return formattedValue;
  };

  export const RemoveFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
  };
  export const IsJSON = (data: any) => {
    try {
      JSON.parse(data);
      return true;
    } catch (error) {
      return false;
    }
  };