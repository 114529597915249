
import { Row, Col, Breadcrumb, Button } from "react-bootstrap";
import { useUser } from "../hooks";
interface PageTitleBoxProps {
	name?: string;
	pageTitle?: string;
	rightItem?: any;
	leftItem?:any;
	breadCrumbItems?:any;
}

/**
 * PageTitleBox
 */
const PageTitleBox = ({ name, rightItem,leftItem,  breadCrumbItems}: PageTitleBoxProps) => {
    const { user } = useUser()

	return (
		<Row>
			<Col>
				<div className="page-title-box ">
					<div style={{display:'block' , color:"#314559"}}> {leftItem}
						<div className="mt-0" >
						<Breadcrumb listProps={{ className: "m-0" }}>

              {(breadCrumbItems || []).map((item:any, index:number) => {
                return item.active ? (
                  <Breadcrumb.Item active key={index}>
                    {item.label}
                  </Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={index} href={item.path}>
                    {item.label}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>

					</div>

					<div className="page-title-right">{rightItem}</div>
				</div>
			</Col>
		</Row>
	);
};

export default PageTitleBox;
