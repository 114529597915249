import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { useToast } from "../../hooks";
import { deleteUser, getAllUser, getUser, saveUser } from "../../helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputCtrl } from "../../controllers";
import { useTranslation } from "react-i18next";
import PhoneCtrl from "../../components/PhoneCtrl";
import Loader from "../../components/Loader";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { getUserPayments } from "../../helpers/api/user";
import { FormatDate } from "../../common/utility";

//Default values
const defaultValues: {
  Id: number;
  firstName: string;
  lastName: string;
  Email: string;
  flair: string;
  roleId: number | string;
  statusId: number | string;
  password: string;
  ProfilePicId: number;
  FullFileUrl: string;
  phoneNumber: number | string;
  gender: number | string;
} = {
  Id: 0,
  firstName: "",
  lastName: "",
  Email: "",
  flair: "",
  password: "",
  FullFileUrl: "",
  ProfilePicId: 0,
  statusId: 1,
  roleId: 2,
  phoneNumber: "",
  gender: "",
};

const Customer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const { showToast, dissmisToast } = useToast();
  const [loading, setLoading]: any = useState(false);
  const [apiStatus] = useState({
    inprogress: false,
  });
  const [phoneValue, setPhoneFields] = useState({
    ContactPhone: "",
  });
  const [userFamily, setUserFamily] = useState<any>([]);
  const [tripDetails, setTripDetails] = useState<any>();
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    Id: number;
    firstName: string;
    Email: string;
    lastName: string;
    phoneNumber: number | string;
    address: string;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const [customer, setCustomer] = useState<any>(null);
  // let location = useLocation();
  // let path = location.pathname;
  // let arr = path.split("/");
  // let id = parseInt(arr[arr.length - 1]);
  const id = Number(params.id);
  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      let requestBody = {
        ...data,
      };

      const response = await saveUser(requestBody);

      if (response.statusCode === 200) {
        showToast("success", response.message);
        setIsEditing(false);

        GetDetails();
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoading(true);

    try {
      const response = await getUser({ id });

      if (response.statusCode === 200) {
        setCustomer(response.data);
        reset(response.data);
      }

      setPhoneFields({
        ContactPhone: response.data.phoneNumber,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const GetUserFamily = async () => {
    setLoading(true);

    try {
      const response = await getAllUser({
        pageNumber: 1,
        pageSize: 10,
        parentAccountId: id,
        isRecentUser: false,
      });

      if (response.statusCode === 200) {
        setUserFamily(response.data.items);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const GetTripsPayment = async () => {
    try {
      const response = await getUserPayments({
        pageNumber: 1,
        pageSize: 5,
        userId: id,
      });
      if (response?.statusCode === 200) {
        setTripDetails(response?.data?.items);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      GetDetails();
    }

    GetUserFamily();

    GetTripsPayment();
  }, [id]);

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    settingKey: string
  ) => {
    const updatedValue = event.target.checked;
    const updatedUserData = { ...customer, [settingKey]: updatedValue };
    try {
      const response = await saveUser(updatedUserData);

      if (response.statusCode === 200) {
        showToast("success", response.message);
        setIsEditing(false);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      showToast("error", "An unexpected error occurred. Please try again.");
    }

    setCustomer(updatedUserData);
  };

  if (!customer) {
    return <Loader />;
  }

  async function deleteData() {
    let response = await deleteUser(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate("/users");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  const RemoveBtn = () => {
    return (
      <button className="btn remove-buttons">
        <FaMinusCircle />
        {t("Remove")}
      </button>
    );
  };

  return (
    <>
      <Card className="mt-2 customer-card">
        <Card.Header className="">
          <div className="background-image">
            <div className="pt-2 ps-2">
              <h6 className="px-2 pt-2 text-white-50 mb-1">
                {t("Pages_Customer")}
              </h6>
              <span className="px-2  text-white">{t("Add_Customer")}</span>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100"></div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between  flex-wrap">
            <div>
              <div className="d-flex align-items-center gap-2">
                <div>
                  <img
                    src={customer?.profilePicFullUrl}
                    className="content-fit rounded-circle"
                    width={60}
                    height={60}
                    alt=""
                  />
                </div>
                <div>
                  <h5 className="fw-semibold mb-1">{customer?.fullName}</h5>
                  <p className="m-0 fw-normal small">{customer?.email}</p>
                </div>
              </div>
            </div>
            <div>
              <DeleteConfirm
                buttonElement={RemoveBtn}
                disabled={apiStatus.inprogress}
                confirm={() => {
                  deleteData();
                }}
                message={t("Are_You_Sure_You_Want_To_Delete_this_customer")}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <Row className="g-2">
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("Notification_Setting")}</h6>
            <p className="m-0 small">{t("ACCOUNT")}</p>
            <ul className="list-unstyled p-0 my-2">
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={customer?.notifyWhenRideIsStarted}
                      onChange={(e) =>
                        handleSwitchChange(e, "notifyWhenRideIsStarted")
                      }
                    />
                  </Form>
                </span>
                {t("Email_me_when_someone_follow_me")}
              </li>
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={customer?.notifyWhenRideIsCancelled}
                      onChange={(e) =>
                        handleSwitchChange(e, "notifyWhenRideIsCancelled")
                      }
                    />
                  </Form>
                </span>
                {t("Email_me_when_ride_cancel")}{" "}
              </li>
              <li className="d-flex align-items-center">
                <span className="me-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={customer?.notifyWhenRideIsCompleted}
                      onChange={(e) =>
                        handleSwitchChange(e, "notifyWhenRideIsCompleted")
                      }
                    />
                  </Form>
                </span>
                {t("Email_me_when_ride_completed")}
              </li>
            </ul>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            className="card-wrapper form "
            onSubmit={handleSubmit(onSubmit)}
          >
            <h6 className="fw-bold">{t("Profile_Information")}</h6>
            <p className="m-0 small text-secondary opacity-50">
              {t("Hi_Im")} {customer?.fullName}, {t("Decisions_paragraph")}
            </p>
            <hr className="my-1" />
            <div className="d-flex align-items-start justify-content-between">
              <div className="">
                {isEditing ? (
                  <Form>
                    <Row className="roboto-thin">
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder={t("username")}
                          label={t("First_Name")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-1 inputChange "
                          componentName="Name"
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder={t("Enter_last_name")}
                          label={t("Last_Name")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-1 inputChange"
                          componentName="Name"
                        />
                      </Col>
                      <Col xl={6}>
                        <PhoneCtrl
                          control={control}
                          name="phoneNumber"
                          id="phoneNumber"
                          placeholder={t("5xxxxxxxxxx")}
                          label={t("Mobile_No")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-1  inputChange w-100"
                          defaultValue={phoneValue.ContactPhone}
                        />
                      </Col>

                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type="email"
                          name="email"
                          id="email"
                          placeholder={t("usernamemail")}
                          label={t("Email")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-1 inputChange "
                          componentName="Email"
                        />
                      </Col>
                      <Col xl={12}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="address"
                          id="address"
                          placeholder={t("Location")}
                          label={t("Location")}
                          showError={showError}
                          required={true}
                          disabled={apiStatus.inprogress}
                          className="mb-1 inputChange"
                          // componentName="Name"
                        />
                      </Col>
                    </Row>
                    <div className="button-list w-100 d-flex justify-content-center  align-items-baseline">
                      <Button
                        type="submit"
                        className="px-4 mt-2 w-50 rounded"
                        disabled={apiStatus.inprogress}
                        variant="primary"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <>
                    <h6 className="theme-color fw-bold">
                      {t("Full_Name")} :
                      <span className="text-dark fw-normal">
                        {customer?.fullName}
                      </span>
                    </h6>
                    <h6 className="theme-color fw-bold">
                      {t("Mobile_No")} :
                      <span className="text-dark fw-normal">
                        {customer?.phoneNumber}
                      </span>
                    </h6>
                    <h6 className="theme-color fw-bold">
                      {t("Email")} :
                      <span className="text-dark fw-normal">
                        {customer?.email}
                      </span>
                    </h6>
                    <h6 className="theme-color fw-bold">
                      {t("Location")} :
                      <span className="text-dark fw-normal">
                        {customer?.address}
                      </span>
                    </h6>
                  </>
                )}
              </div>

              <div>
                <CiEdit color="#307E8A " size={25} onClick={toggleEditMode} />
              </div>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="card-wrapper">
            <h6 className="fw-bold">{t("User_Family")}</h6>
            <div className="">
              {userFamily.length > 0 ? (
                userFamily.map((user: any) => (
                  <div key={user.id} className="mb-3">
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex">
                        <div className="custom-image rounded-circle bg-secondary">
                          <img
                            src={user?.profilePicFullUrl}
                            alt=""
                            className="custom-image rounded-circle bg-secondary
 "
                          />
                        </div>

                        <div className="ms-2">
                          <h4 className="fw-normal fs-5 mb-1">
                            {user?.fullName}
                          </h4>
                          <p className="small text-secondary opacity-75 m-0">
                            {`${user?.totalPoints} Points`}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="my-1" />
                  </div>
                ))
              ) : (
                <p>{t("No_user_family_data_available")}</p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Card className="card-wrapper p-2 my-2">
        <h6 className="fw-bold">{t("Platform_Setting")}</h6>
        <Row className="row-cols-lg-3 row-cols-xl-4 row-cols-md-3 mt-2 row-cols-sm-2 row-cols-1 g-2">
          {tripDetails?.length !== 0 ? (
            tripDetails?.map((trips: any) => (
              <Col>
                <Card
                  key={trips.id}
                  className="border border-dark px-md-2 px-sm-2 p-1 h-100 mb-0"
                >
                  <div className="check-mark mb-0">
                    <FaCircleCheck className="fs-4" />
                  </div>
                  <div className="text-center">
                    <h6 className="mb-0 text-center mb-2">
                      {trips?.metadata.CustomerName}
                    </h6>

                    <small className="extra-small fw-semibold m-0">
                      {trips.status === "succeeded"
                        ? t("Your_payment_has_been_successfully_done")
                        : trips.status === "failed"
                        ? t("Your_payment_has_been_failed")
                        : t("Your_payment_is_pending")}
                    </small>
                  </div>
                  <div className="rounded chekout-info border p-md-2 p-sm-2 ">
                    <div className="d-flex justify-content-between  align-items-center">
                      <small>{t("Balance")}</small>
                      <h6 className="theme-color mb-0">
                        {trips?.metadata.Amount}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small>{t("Payment_Status")}</small>
                      <h6 className="success-status m-0">{t(trips.status)}</h6>
                    </div>
                    <hr className="my-1 bg-dark opacity-75" />
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small>{t("Ref_No")}</small>
                      <h6 className="small-text m-0">
                        {trips.balanceTransactionId}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small>{t("Merchant_Name")}</small>
                      <h6 className="small-text m-0">
                        {trips.metadata.DriverName}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <small>{t("Payment_Method")}</small>
                      <h6 className="small-text m-0">
                        {trips.metadata.PaymentMethod}{" "}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2 ">
                      <small className="d-flex align-items-center">
                        {t("Payment_Time")}{" "}
                      </small>
                      <h6 className="small-text m-0 ">
                        {FormatDate(trips.created, "lll")}
                      </h6>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <p>{t("No_data_available")}</p>
          )}
        </Row>
      </Card>
    </>
  );
};

export default Customer;
