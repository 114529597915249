import { APICore } from "./apiCore";

const api = new APICore();


function getdashboard(params:{type:string,filters:any}) {
    const baseUrl = "/api/Utility/GetDashboard";
    return api.get(`${baseUrl}`,params);
}

export { getdashboard }