import { APICore } from "./apiCore";

const api = new APICore();

function saveTripFareRule(params: any) {
    const baseUrl = "/api/TripFareRule/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllTripFareRule(params: {
    pageNumber: number;
    pageSize: number;
    //  query: string;

}) {
    const baseUrl = "/api/TripFareRule/GetAll";
    return api.get(`${baseUrl}`, params);
}

function getTripFareRule(params: { id: number | string }) {
    const baseUrl = "/api/TripFareRule/Get";
    return api.get(`${baseUrl}`, params);
}

const deleteTripFareRule = (id: any) => {
    const baseUrl = "/api/TripFareRule/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }

export { saveTripFareRule, getTripFareRule, getAllTripFareRule, deleteTripFareRule }