import { Button, Card} from "react-bootstrap";
import {useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import { getAllVehicleType } from "../../helpers";
import { useTranslation } from "react-i18next";
import SelectFilter from "../../components/SelectFilter";
import Search from "../../components/Search";
import { useState } from "react";

const Vehicle = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [mystatus, setMyStatus] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      Header: t("Sr_No"),
      accessor: "id",
    },
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("icon"),
      accessor: (row: any) => {
        return row?.fileFullUrl ? (
          <img
            src={row.fileFullUrl}
            style={{ width: "30px", height: "30px" }}
          />
        ) : (
          "NA"
        );
      },
    },
    {
      Header: t("Price"),
      accessor: (row: any) => <span className="theme-color">${row.price}</span>,
    },
    {
      Header: t(" "),
      accessor: () => <div className="opacity-50">Edit</div>,
    },
  ];
  const {
    list: partnerList,
    isFetching,
    pagination,
    reCall: refetchData,
  } = useApi(
    "GetAllVehicleType",
    (data: any) =>
      getAllVehicleType({
        pageNumber: 1,
        pageSize: 10,
        filters: mystatus ? mystatus : "",
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  const StatusOptions: any[] = [
    {
      label: t("ADMIN"),
      value: "ADMIN",
    },
    {
      label: t("USER"),
      value: "USER",
    },
    {
      label: t("DRIVER"),
      value: "DRIVER",
    },
    {
      label: t("ALL"),
      value: "",
    },
  ];
  const handleStatusChange = (selectedStatus: {
    label: string;
    value: string;
  }) => {
    setMyStatus(selectedStatus.value);
    refetchData({
      filters: selectedStatus.value,
    });
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <PageTitleBox
              leftItem={
                <div className="d-flex flex-wrap">
                  <SelectFilter
                    pagination={pagination}
                    options={StatusOptions}
                    paramName=""
                    onChange={handleStatusChange}
                  />

                  <Button
                    variant="primary"
                    className="btn ms-1 rounded-1 "
                    onClick={() => navigate("./add")}
                  >
                    {t("ADD_NEW")} <i className="uil uil-plus"></i>
                  </Button>
                </div>
              }
            />{" "}
            <div className="w-75">
              {" "}
              <Search pagination={pagination} width={"w-100"} />
            </div>
          </div>
          <Table
            isFetching={isFetching}
            columns={columns}
            data={partnerList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            isSelectable={true}
            {...pagination}
            onRowClick={(row) => {
              navigate("" + row.original.id);
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Vehicle;
