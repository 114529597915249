import React, { useEffect, useRef } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button, } from "react-bootstrap";
import { InputCtrl, SelectCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useApi, useToast } from "../../hooks";
import { deleteAward, getAward, saveAward } from "../../helpers/api/award";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { deleteCoupon, getAllCoupon, getCoupon, saveCoupon } from "../../helpers/api/promocode";
import moment from "moment";
import { FormatDate } from "../../common/utility";

import Table from "../../components/Table";
import { getAllPartners } from "../../helpers/api/partner";



//Default values
const defaultValues: {
  id: number;
  name: string;
  code: string;
  partnerId: number | string;
  usageLimit: number | string;
  validTillDate: number | string;
  status:any;
  type: string;
  discount: number | string;
  validFromDate:number|string;
} = {
  id: 0,
  name: "",
  code: "",
  partnerId: "",
  usageLimit: "",
  validTillDate: "",
  status:"",
  type: "",
  discount: "",
  validFromDate:""
};

const PromocodeDetails = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const [roles, setRoles] = useState<any[]>([]);
  const { showToast, dissmisToast } = useToast();
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();
  const [partners, setPartners] = useState<any[]>([]);

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    id: number;
    name: string;
    code: string;
    partnerId: number | string;
    usageLimit: number | string;
    validTillDate: number | string;
    status: any;
    type: string;
    discount: number | string;
    validFromDate:number|string
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    id: number;
    name: string;
    code: string;
    partnerId: number;
    usageLimit: number;
    validTillDate: number | string;
    status:any;
    type: string;
    discount: number;
    validFromDate:number|string

  }> = async (data) => {
    try {
      const response = await saveCoupon({
        id: data.id,
        name: data.name,
        code: data.code,
        partnerId: data.partnerId,
        usageLimit: data.usageLimit,
        validTillDate: data.validTillDate,
        status: data.status ===  "ACTIVE"  ? true  : false,
        type: data.type,
        discount: 0,
        validFromDate:data.validFromDate
      });
      navigate(-1);

      if (response.statusCode === 200) {
        showToast("success", response.message || "success");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true)
    try {
      const response = await getCoupon({ id });
      // Use reset method to update the form values
      reset({
        id: response.data.id,
        name: response.data.name,
        code: response.data.code,
        partnerId: response.data.partnerId,
        usageLimit: response.data.usageLimit,
        validTillDate: moment(response.data.validTillDate).format("YYYY-MM-DD"),
        status: response.data.status === true ? "ACTIVE" : "INACTIVE",
        type: response.data.type,
        validFromDate: moment(response.data.validFromDate).format("YYYY-MM-DD"),
        discount:"0"
      });
      setLoadoing(false)
      // showToast("success", response.message);
    } catch (error) {
      setLoadoing(false)
      console.log(error, "error");
    }
  };

  async function deleteData() {
    let response = await deleteCoupon(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate(-1);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  const fetchpartners = async () => {
    try {
      const response = await getAllPartners({
        pageNumber: 1,
        pageSize: 100,
      });
      if (response.statusCode === 200) {
        
        setPartners(
          response.data.items.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

 


  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
    fetchpartners()
  }, []);



 

  const statusData: any[] = [
    {
      label: t("ACTIVE"),
      value: "ACTIVE"
    },
    {
      label: t("INACTIVE"),
      value: "INACTIVE"
    }
  ]

  const typeData: any[] = [
    {
      label: t("Fixed"),
      value: 'fixed'
    },
    {
      label: t("Percent"),
      value: 'percent'
    },
    {
      label: t("Value"),
      value: 'value'
    }
  ]
  const UsageTime: any[] = [
    {
      label: t("Single"),
      value: '1'
    },
    {
      label: t("Multiple"),
      value: '2'
    },
  ]


  const columns = [
    {
      // Header: t("ID"),
      Header: t("Sr_No"),
      accessor: "id",
    },
    {
      Header: t("Title"),
      accessor: "name",
    },
    {
      Header: t("Promocode"),
      accessor: "code",
    },
    {
      Header: t("Partners"),
      accessor: "partnerName",
    },

    {
      Header: t("Usage_Time"),
      accessor: "usageLimitAccess",
    },

    {
      Header: t("Type"),
      accessor: "type",
    },

    {
      Header: t("Expiry_Date"),
      accessor: (row: any) => {
        return FormatDate(row.validTillDate, 'lll')
      }
    },

    {
      Header: t("Status"),
      accessor: (row:any)=>{
        return <div className={`text-light  px-1 rounded-3 text-center ${row.status?"status-color":"bg-secondary"}`}>
{row.status?"Active":"Inactive"}
        </div>}
    },

    {
      Header: t(" "),
      accessor: () => (
        <div className="opacity-50 float-end">
          Edit
        </div>
      ),
    },

  ];

  const {
    list: partnerList,
    isFetching,
    pagination,
  } = useApi(
    "GetAllAwards",
    (data: any) =>
      getAllCoupon({
        pageNumber: 1,
        pageSize: 10,
        //  query: "",
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  return (
    <>
      <PageTitleBox name="TRIAD School District" pageTitle={t('PROMOCODE_DETAILS')} />
      <form name="chat-form" id="chat-form" onSubmit={handleSubmit(onSubmit)}>
        <Card >
          <Card.Body >
            {loading && <Loader />}
            <Row className="p-2">
              <Col xl={12}>
                <Row className="roboto-thin ">
                  <Col xl={6}>
                   
                     <InputCtrl
                      control={control}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t('Title')}
                      label={t('Title')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"

                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="code"
                      id="code"
                      placeholder={t('Promocode')}
                      label={t('Promocode')}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"

                    />
                  </Col>
                  <Col xl={6}>
                    <SelectCtrl
                      control={control}
                      // type="text"
                      name="partnerId"
                      id="partnerId"
                      placeholder={t('Enter_partner')}
                      label={t('Partner')}
                      showError={showError}
                      options={partners.map((item) => ({ label: t(item.label), value: item.value }))}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>

                  <Col xl={6}>

                    <SelectCtrl
                      control={control}
                      name="usageLimit"
                      id="usageLimit"
                      placeholder={t('Select')}
                      label={t('Usage_Time')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={UsageTime}
                      showError={showError}
                    />

                  </Col>

                  <Col xl={6}>
                    <SelectCtrl
                      control={control}
                      name="type"
                      id="type"
                      placeholder={t('Select')}
                      label={t('Type')}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange "
                      options={typeData}
                      showError={showError}
                      
                    />
                  </Col>
                  <Col xl={6}>
                   <SelectCtrl
                      control={control}
                      name="status"
                      id="statusId"
                      placeholder={t('Select')}
                      label={t('Status')}
                      required={false}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                      options={statusData}
                      showError={showError}
                    />

                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="date"
                      name="validFromDate"
                      id="validFromDate"
                      placeholder={t('date')}
                      label={t('From_the_Date')}
                      showError={showError}
                      required={false}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                  <Col xl={6}>
                    <InputCtrl
                      control={control}
                      type="date"
                      name="validTillDate"
                      id="validTillDate"
                      placeholder={t('to_date')}
                      label={t('To_Date')}
                      showError={showError}
                      required={false}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-items-baseline ">
              <div className="button-list w-100 d-flex justify-content-center  align-items-baseline">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={apiStatus.inprogress}
                  className="px-4 w-50 rounded button-54">
                  {t('ADD')}
                </Button>

              </div>
              <div>
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>

      </form>

      <Card>
        <Card.Body>
        <div>
            <h5 className="fw-bold text-center  ">{t("Latest_Added")}</h5>
          </div>
         

          <Table
            isFetching={isFetching}
            columns={columns}
            data={partnerList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            {...pagination}
           
          />
        </Card.Body>
      </Card>

    </>
  );
};

export default PromocodeDetails;
