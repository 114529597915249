import { useEffect, useState } from "react";
import { Button} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Logo from "../../assets/images/Logo.svg";
import LogoWhite from "../../assets/images/Logo_white.svg";

//actions
import { resetAuth} from "../../redux/actions";
import {AppDispatch } from "../../redux/store";


import i18n from "../../i18n"; 
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputCtrl } from "../../controllers";
import { useForm } from "react-hook-form";
import useToast from "../../hooks/useToast";
import { RemoveFromLocalStorage, RESET_PASSWORD_TOKEN_KEY } from "../../common/utility";
import moment from "moment";

import { updatepassword } from "../../helpers/api/user";

interface IForgetPass {
  email:string;
  otp:any;
    oldPassword: any;
    newPassword:any; 
}

//Default values
const defaultValues: IForgetPass = {
  email:"",
  otp:"",
oldPassword: "",
  newPassword:"",
};

const ResetPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [progress, setProgress] = useState(false);
  const { t } = useTranslation();
  const { showToast, dissmisToast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IForgetPass>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });


  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  const onSubmit = async (data: any) => {
    try {
      const email = searchParams.get("email");
      const otp = searchParams.get("otp");
      const requestBody = {
        email,
        newPassword: data.newPassword,
        otp,
      };
      const response = await updatepassword({
        ...requestBody,
      });
      if (response.statusCode === 200) {
        showToast("success", response.message || "success");
        RemoveFromLocalStorage(RESET_PASSWORD_TOKEN_KEY);
        navigate("/auth/login");
      } else {
        showToast("error", response.message || "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  const languageOptions = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "Arabic",
      value: "ar",
    },
  ];
  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <>
      <div className="container-fluid   h-100">
        <div className="row text-start   p-2 justify-content-center align-items-center">
          <div
            className="col-md-6  d-flex align-items-center   flex-column justify-content-between"
            style={{ height: "90vh" }}
          >
            {/* logo */}
            <div className="d-flex align-items-center w-75 gap-2">
              <img
                className="img-fluid logo"
                loading="eager"
                alt=""
                src={Logo}
              />
              <div>
                <span className="taxi fs-3">Joud</span>
                <span className="taxi fs-3">ma</span>
              </div>
            </div>
            {/* form */}

            <div className="text-start w-75">
              <div className="text-start">
                <h1 className="sign-up">{t("Reset your password?")}</h1>
                <h3 className="create-an-account pb-3 pt-2">
                  {t("Reset your password ")}
                </h3>
              </div>
              <form  onSubmit={handleSubmit(onSubmit)}>
                <InputCtrl
                  control={control}
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                  placeholder="Enter Password"
                  showError={showError}
                  required={true}
                  disabled={progress}
                  className="mb-3 bg-dark-field"
                  label={"Password"}
                
                />
                <InputCtrl
                  control={control}
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="Enter Confirm Password"
                  showError={showError}
                  required={true}
                  disabled={progress}
                  className="mb-3 bg-dark-field"
                  label={"Confirm Password"}
                  rules={{
                    validate: (value) =>
                      value === getValues().newPassword ||
                      "The passwords do not match",
                  }}
                
                />

                <div className="button-54 ">
                  <Button
                    style={{ width: "100%" }}
                    className="button-54"
                    type="submit"
                    disabled={progress}
                   
                  >
                    {t("Continue")}
                  </Button>
                </div>
              </form>
            </div>

            <div></div>
          </div>

          <div className="col-md-6 right_side ">
            <img src={LogoWhite} className="img-fluid" alt="" />

            <div className="lang-sel custome-div  ">
              <Select
                options={languageOptions}
                defaultValue={languageOptions.find(
                  (option) => option.value === "en"
                )}
                onChange={(e: any) => {
                  changeLanguage(e.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
