import { Button, Card, Col,  Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import { FormatDate } from "../../common/utility";
import AwardStasProps from "../awards/AwardStasCard";
import { getAllRides } from "../../helpers/api/ride";
import { getAllDriverApproval } from "../../helpers/api/approval";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import Search from "../../components/Search";
import { useState } from "react";





const DriverApproval = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [search,setSearch]=useState<boolean>(false)
  
const columns = [
  {
    Header: t("Sr_No"),
     accessor: "id",
  },
  {
    Header: t("Name"),
    accessor: "fullName",
  },
  {
    Header: t("Phone"),
    accessor: (row: any) => {
        return row?.phoneNumber || "NA";
    },
  },
  {
    Header: t("Email"),
    accessor: "email",
  },

  {
    Header: t('Vehicle_Type'),
    accessor: (row: any) => {
        return row?.vehicleType || "NA";
    },
  },

  {
    Header: t("Vehicle_R_No"),
    accessor: (row: any) => {
        return row?.registrationNumber || "NA";
    },
  },

  {
    Header: t("Driver_Licence"),
    accessor: (row: any) => {
        return row?.licenseNumber || "NA";
    },
  },

  {
    Header: t("Vehicle_Insurance"),
    accessor: (row: any) => {
        return row?.insuranceNumber|| "NA";
    },
  },
  {
    Header: t("Car_Make"),
    accessor: (row:any) => {
        return row?.make || "NA";
    },
  },

  {
    Header: t("Car_Model"),
    accessor: (row: any) => {
      return row?.vehicleModel || "NA";
    },
  },

  {
    Header: t("Image"),
    accessor: (row:any) => {
        return <img src={row?.vehicleTypeImageFullUrl} style={{width:'37px', height:'37px',border:"2px solid #307e8a"}} className=" img-fluid   rounded-2 "/>;
    },
  },
  {
    Header: t("Status"),
    accessor:(row:any)=>{
      return <div className={`text-light  px-1 rounded-3 text-center ${row.approvalStatus==="PENDING"?"bg-secondary":row.approvalStatus==="APPROVED"?"status-color":"bg-danger"}`}>
{row.approvalStatus}
      </div>
        },
  },

];

  const {
    list: partnerList,
    isFetching,
    pagination,
  } = useApi(
    "GetAllDriverApproval",
    (data: any) =>
    getAllDriverApproval({
        pageNumber: 1,
        pageSize: 10,
        //  query: "",
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );


  return (
    <>
    
        <Card className="mt-3">
          <Card.Body>
          <PageTitleBox
        name=""
        pageTitle={t('DRIVER_APPROVALS')}
        rightItem={
          <Row className="justify-content-end">
            <Col className="d-flex">
             
            {
  search?<Search pagination={pagination} />:<FaSearch size={18} className="me-2 opacity-75" onClick={()=>setSearch(true)}/>
}
            </Col>
          </Row>
        }
      />
            <Table
              isFetching={isFetching}
              columns={columns}
              data={partnerList}
              sizePerPageList={[]}
              isSortable={true}
              isSelectable={true}
              pagination={true}
              {...pagination}
              onRowClick={(row) => {
                navigate("" + row.original.id);
              }}
            />
          </Card.Body>
        </Card>
    </>
  );
};

export default DriverApproval;
