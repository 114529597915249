import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteConfirm = ({
  buttonElement,
  confirm = () => {},
  disabled = false,
  isHide = false,
  iconColor = "text-danger",
  iconMargin = "me-2",
  iconSize = "fs-24",
  iconClass = "uil uil-trash-alt",
  message = undefined || "",
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return !isHide ? (
    <>
      <OverlayTrigger
        trigger="click"
        show={isOpen}
        key={"Delete"}
        overlay={
          <Popover popper id={`delete`}>
            <Popover.Body className="h5 font-bold">
              {message || t("Are_you_sure")}
            </Popover.Body>
            <div className="d-flex justify-content-between">
              <i
                className="uil uil-check fs-24 cursor-pointer text-danger mx-2"
                onClick={() => {
                  handleToggle();
                  confirm();
                }}
              ></i>
              <i
                className="uil uil-times fs-24 cursor-pointer text-success mx-2"
                onClick={handleToggle}
              ></i>
            </div>
          </Popover>
        }
      >
        {buttonElement ? (
          <div
            onClick={() => {
              if (!disabled) {
                handleToggle();
              }
            }}
          >
            {buttonElement()}
          </div>
        ) : (
          <i
            className={`${iconClass} cursor-pointer ${iconColor} ${iconMargin} ${iconSize}`}
            onClick={() => {
              if (!disabled) {
                handleToggle();
              }
            }}
          ></i>
        )}

        {/* <i
					className={`${iconClass} cursor-pointer ${iconColor} ${iconMargin} ${iconSize}`}
					onClick={() => {
						if (!disabled) {
							handleToggle();
						}
					}}
				></i> */}
      </OverlayTrigger>
    </>
  ) : null;
};

export default DeleteConfirm;
