import { APICore } from "./apiCore";

const api = new APICore();


function getDriverPerformance(params: {
    pageNumber: number;
    pageSize: number;
    //  query: string;

}) {
    const baseUrl = "/api/User/GetDriverPerformance";
    return api.get(`${baseUrl}`, params);
}

function getDriver(params: { id: number | string }) {
    const baseUrl = "/api/User/GetDriverWithDetails";
    return api.get(`${baseUrl}`, params);
}

function payout(params: any) {
    const baseUrl = "/api/Payment/CreatePayout";
    return api.create(`${baseUrl}`, params);
}



export { getDriverPerformance,payout,getDriver }