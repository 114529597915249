import { useEffect } from "react";
import PageTitleBox from "../../components/PageTitleBox";
import { Card, Col, Row, Button } from "react-bootstrap";
import { InputCtrl } from "../../controllers";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteConfirm from "../../components/Inplace Confirm/DeleteConfirm";
import { useToast } from "../../hooks";
import FileUpload from "../forms/FileUpload";
import { deleteFile, getFile } from "../../helpers/api/file";
import Loader from "../../components/Loader";
import {
  deleteVehicleType,
  getVehicleType,
  saveVehicleType,
} from "../../helpers";
import { useTranslation } from "react-i18next";

const defaultValues: {
  id: number;
  name: string;
  iconFileId: number | string;
  sortIdx: number | string;
  price: number;
  fileFullUrl: string;
} = {
  id: 0,
  name: "",
  iconFileId: 1,
  sortIdx: 1,
  price: 0,
  fileFullUrl: "",
};

const VehicleTypeDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [roles, setRoles] = useState<any[]>([]);
  const { showToast, dissmisToast } = useToast();
  const [uploadedFile, setUploadedFile]: any = useState({});
  const [isUploaded, setIsUploaded] = useState(false);
  const [imageUrl, setImageUrl]: any = useState("");
  const [loading, setLoadoing]: any = useState(false);
  const { t } = useTranslation();

  const [apiStatus] = useState({
    inprogress: false,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    id: number;
    name: string;
    iconFileId: number | string;
    sortIdx: number | string;
    price: number;
    fileFullUrl: string;
  }>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });
  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || t("Field_is_required") : null;
  };

  let location = useLocation();
  let path = location.pathname;
  let arr = path.split("/");
  let id = parseInt(arr[arr.length - 1]);

  const onSubmit: SubmitHandler<{
    id: number;
    name: string;
    iconFileId: number | string;
    sortIdx: number | string;
    price: number;
    fileFullUrl: string;
  }> = async (data) => {
    // saveVehicleTypeData
    try {
      const response = await saveVehicleType({
        id: data.id,
        name: data.name,
        fileUrl: imageUrl,
        iconFileId: uploadedFile?.id,
        sortIdx: 0,
        price: data.price,
        fileFullUrl: data.fileFullUrl,
      });
      navigate(-1);

      if (response.statusCode === 200) {
        showToast("success", response.message);
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDetails = async () => {
    setLoadoing(true);
    try {
      const response = await getVehicleType({ id });
      // Use reset method to update the form values
      reset({
        id: response.data.id,
        name: response.data.name,
        iconFileId: response.data.iconFileId,
        sortIdx: response.data.sortIdx,
        price: response.data.price,
        fileFullUrl: response.data.fileFullUrl,
      });
      if (response?.data?.iconFileId) {
        const logoId: any = response?.data?.iconFileId;
        const imageResponse = await getFile({ id: logoId });

        if (
          imageResponse &&
          imageResponse?.data &&
          imageResponse?.data?.id !== 0
        ) {
          setImageUrl(imageResponse?.data?.fullFileUrl);
          setIsUploaded(true);
          setUploadedFile(imageResponse?.data);
        } else {
          setImageUrl("");
          setIsUploaded(false);
          setUploadedFile({});
        }
      }
      setLoadoing(false);
      // showToast("success", response.message);
    } catch (error) {
      console.log(error, "error");
      setLoadoing(false);
    }
  };

  async function deleteData() {
    let response = await deleteVehicleType(id);
    try {
      if (response.statusCode === 200) {
        showToast("success", response.message);
        navigate("/vehicle");
      } else {
        showToast("error", response.message);
      }
    } catch (error) {
      console.log(response.error);
    }
  }

  useEffect(() => {
    if (Number(params.id)) {
      GetDetails();
    }
  }, []);

  const handleCallBack = (data: any) => {
    setUploadedFile(data);
    setIsUploaded(true);
    setImageUrl(data?.fullFileUrl);
  };

  const deleteImage = async () => {
    const imageId = uploadedFile?.id;
    const res = await deleteFile(imageId);
    setIsUploaded(false);
    setImageUrl("");
    setUploadedFile({});
  };

  return (
    <>
      {loading && <Loader />}
      <form
        name="chat-form"
        id="chat-form"
        className="w-75  m-auto roboto-thin p-5 "
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <div></div>
                    {isUploaded ? (
                      <div className="d-flex justify-content-center">
                        <img className="uploaded-image" src={imageUrl} />

                        <div className="remove-button">
                          <DeleteConfirm
                            isHide={!isUploaded}
                            disabled={apiStatus.inprogress}
                            confirm={() => {
                              deleteImage();
                            }}
                            message= {t("Delete_The_Logo")}
                          />
                        </div>
                      </div>
                    ) : (
                      <FileUpload handleCallBack={handleCallBack} />
                    )}
                  </Col>
                  <Col xl={12} className="mt-2">
                    <InputCtrl
                      control={control}
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t("Vehicle_Name")}
                      label={t("Name")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                  <Col xl={12}>
                    <InputCtrl
                      control={control}
                      type="text"
                      name="price"
                      id="price"
                      placeholder={t("Enter_Price")}
                      label={t("Price")}
                      showError={showError}
                      required={true}
                      disabled={apiStatus.inprogress}
                      className="mb-3 inputChange"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-baseline">
              <div className="button-list d-flex justify-content-center w-100 align-items-baseline">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={apiStatus.inprogress}
                  className="px-4 button-54  w-50 rounded"
                >
                  {t("ADD")}
                </Button>
                <Button
                  variant="outline-secondary"
                  className="px-4  w-50 rounded ms-3 py-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </div>
              <div>
                <DeleteConfirm
                  isHide={!Number(params.id)}
                  disabled={apiStatus.inprogress}
                  confirm={() => {
                    deleteData();
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </form>
    </>
  );
};

export default VehicleTypeDetails;
