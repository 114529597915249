import { Button, Card, Col,  Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import { getAllAchivements } from "../../helpers/api/achievement";
import { useTranslation } from "react-i18next";
import Search from "../../components/Search";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";


const Achievement = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const [search,setSearch]=useState<boolean>(false)

  let count = 1;
  
  const columns = [
    {
      Header: t("Sr_No"),
     accessor: "id"
    },
    {
      Header: t("Image"),
      accessor:(row: any) => {
        return row?.fullFileUrl ?<img src={row.fullFileUrl} style={{width:'30px', height:'30px'}} />: "NA";
      },
    },
    {
      Header: t("Level"),
      accessor: "name",
    },
    {
      Header: t("Minimum_Trips"),
      accessor:"minimumTrips"
    },
    {
      Header: t("Reward_Amount"),
      accessor:(row:any)=>{
        return `$ ${row.amount}`
      }
    },

    {
      Header: t("Status"),
      accessor:(row:any)=>{
        return <div className={`text-light  px-1 rounded-3 text-center ${row.status==="INACTIVE"?"bg-secondary":"status-color"}`}>
{row.status}
        </div>
      },
    },
    {
      Header: t(" "),
      accessor:() => <span className="opacity-50 float-end">Edit</span>
          ,
    }
  ];
  const {
    list: partnerList,
    isFetching,
    pagination,
  } = useApi(
    "GetAllAchievements",
    (data: any) =>
      getAllAchivements({
        pageNumber: 1,
        pageSize: 10,
        //  query: "",
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  
  return (
    <>
  
    
        <Card className="mt-3">
          <Card.Body>
          <PageTitleBox
        name=""
        pageTitle={t('ACHIEVEMENTS')}
        leftItem={<Button
          variant="primary"
          className="btn rounded-1 "
          onClick={()=>navigate("./add")}
          >
         {t("ADD_NEW")} <i className="uil uil-plus"></i> 
        </Button>}
        rightItem={
          <Row className="justify-content-end">
            <Col>
             
            {
  search?<Search pagination={pagination} />:<FaSearch size={18} className="me-2 opacity-75" onClick={()=>setSearch(true)}/>
}
            </Col>
          </Row>
        }
      />
            <Table
              isFetching={isFetching}
              columns={columns}
              data={partnerList}
              sizePerPageList={[]}
              isSortable={true}
              pagination={true}
              isSelectable={true}
              {...pagination}
              onRowClick={(row) => {
                navigate("" + row.original.id);
              }}
            />
          </Card.Body>
        </Card>
    </>
  );
};

export default Achievement;
