import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useApi, useToast } from "../../hooks";
import PageTitleBox from "../../components/PageTitleBox";
import Table from "../../components/Table";
import { getDriverPerformance } from "../../helpers/api/driverPerformance";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import SelectFilter from "../../components/SelectFilter";
import { IoFilter } from "react-icons/io5";
import Search from "../../components/Search";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";

const DriverPerformance = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [mystatus, setMyStatus] = useState("");
  const { t } = useTranslation();
  const [search,setSearch]=useState<boolean>(false)

  // Function to handle payout

  const payout = async (id:any) => {
    try {
      const response = await axios.post('/api/Payment/CreatePayout', { amount: id });
      if (response.status === 200) {
        showToast("success", "Payout successful");
      } else {
        throw new Error('Failed to process payout');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      Header: t("Sr_No"),
      accessor: "id"
    },
    {
      Header: t("Name"),
      accessor: "fullName",
    },
    {
      Header: t("Phone_Number"),
      accessor: (row: any) => {
          return row?.phoneNumber || "NA";
      },
    },
    {
      Header: t("Type_of_Vehicle"),
      accessor: "vehicleType"
    },
    {
      Header: t("Vehicle_Number"),
      accessor: "registrationNumber"
    },
    {
      Header: t("Ratings"),
      accessor: "avgRating",
    },
    {
      Header: t("Earnings"),
      accessor:(row:any)=>(<span className="theme-color">${row.totalEarnings}</span>)
    },
    // {
    //   Header: " ",
    //   accessor: (row:any) => (
    //     <span className="opacity-50 " role="button"
    //     onClick={() => navigate("" + row.id)}
    //     >
    
    //       Edit
    //     </span>
    //   ),
    // },
  ];
  const StatusOptions: any[] = [
    {
      label: t("ADMIN"),
      value: "ADMIN",
    },
    {
      label: t("USER"),
      value: "USER",
    },
    {
      label: t("DRIVER"),
      value: "DRIVER",
    },
    {
      label: t("ALL"),
      value: "",
    },
  ];
  const handleStatusChange = (selectedStatus: { label: string; value: string }) => {
    setMyStatus(selectedStatus.value);
    refetchData({
      filters: selectedStatus.value
    });
  };


  const {
    list: partnerList,
    isFetching,
    pagination,
    reCall: refetchData
  } = useApi(
    "GetAllDriverApproval",
    (data: any) =>
    getDriverPerformance({
        pageNumber: 1,
        pageSize: 10,
        userId:0,
        filters: mystatus ? mystatus : '',
        ...data,
      }),
    {
      enabled: true,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  return (
    <>
      <Card className="mt-4">
        <Card.Body>
        <PageTitleBox

            leftItem={<div className="d-flex">
              <SelectFilter pagination={pagination} options={StatusOptions} paramName='' onChange={handleStatusChange} />

              {/* <Button
                variant="primary"
                className="btn ms-1 rounded-1 "
                onClick={() => navigate("./add")}
              >
                {t("ADD NEW")} <i className="uil uil-plus"></i>
              </Button> */}
            </div>}
            rightItem={
              <Row className="justify-content-around">
                <Col className="d-flex " xs={12} md={12}>


{
  search?<Search pagination={pagination} />:<FaSearch size={18} className="me-2 opacity-75" onClick={()=>setSearch(true)}/>
}
               
                  <IoFilter className="fw-bold h-100 mx-2" size={20} />


                </Col>
              </Row>
            }
          />
          <Table
            isFetching={isFetching}
            columns={columns}
            data={partnerList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            isSelectable={true}
            {...pagination}
            onRowClick={(row) => {
              navigate("" + row.original.id);
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default DriverPerformance;
