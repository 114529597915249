import { APICore } from "./apiCore";

const api = new APICore();

function saveUser(params: any) {
    const baseUrl = "/api/User/Save";
    return api.create(`${baseUrl}`, params);
}

function getAllUser(params: {
    pageNumber: number;
    pageSize: number;
    isRecentUser:boolean;
    parentAccountId:number

}) {
    const baseUrl = "/api/User/GetAll";
    return api.get(`${baseUrl}`, params);
}
function getLatestTrip(params:any){
    const baseUrl = "/api/Trip/GetAll";
    return api.get(`${baseUrl}`,params);
}

function getUserPayments(params:any){
    const baseUrl = "api/Utility/GetPaginatedTransactionHistory";
    return api.get(`${baseUrl}`,params);
}

function getUser(params: { id: number | string }) {
    const baseUrl = "/api/User/Get";
    return api.get(`${baseUrl}`, params);
}

function getUserGetDriverWithDetails(params: any) {
    const baseUrl = "/api/User/GetDriverWithDetails";
    return api.get(`${baseUrl}`,params);
}

function ApproveOrReject(params: any) {
    const baseUrl = "/api/User/ApproveOrReject";
    return api.create(`${baseUrl}?id=${params.id}&approvedBy=${params.approvedBy}&action=${params.action}`,{});
}

const deleteUser = (id: any) => {
    const baseUrl = "/api/User/Delete";
    return api.delete(`${baseUrl}?id=${id}`);
  }
  function Recoverypassword(params: any) {
    const baseUrl = "/api/User/SendPasswordRecoveryCode";
    return api.get(`${baseUrl}`,params);
}

function updatepassword(params:any){
    const baseUrl="/api/User/ChangePassword";
    return api.get(`${baseUrl}`,params)
}


const  Userverifyotp = (params: any) => {
    const baseUrl = "/api/User/VerifyOtp";
    return api.get(`${baseUrl}`,params);
  }
 

export { getUserPayments, saveUser, getUser, getAllUser, deleteUser, getUserGetDriverWithDetails, ApproveOrReject,Recoverypassword,Userverifyotp,updatepassword,getLatestTrip}